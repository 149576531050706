// SALESFORCE LOGIN
/**
 * @see [OAuth Authorization Flows](https://help.salesforce.com/s/articleView?language=en_US&id=sf.remoteaccess_oauth_flows.htm)
 */
export enum GRANT_TYPE {
    CODE = "code",//https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_web_server_flow.htm
    TOKEN = "token",//https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_user_agent_flow.htm
    PASSWORD = "password",
    AUTH_CODE = "authorization_code",
}

/**
 * @deprecated
 */
export interface SalesforceCredentialsFull {
    grant_type: string,
    client_id: string,
    client_secret: string,
    username: string,
    password: string,
    securityToken: string,
}//TODO("add redirect, https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_web_server_flow.htm&type=5")

/**
 * @deprecated
 */
export type SalesforceOAuthCredentialsOAuth =
    Pick<SalesforceCredentialsFull, "grant_type" | "client_id" | "client_secret">
    | {code: string, grant_type: GRANT_TYPE.AUTH_CODE, redirect_uri: string}

export interface SalesforceLoginResponse {
    accessToken: string,
    instanceUrl: string,
}

// GOOGLE LOGIN
export interface SignInState {
    email: string,
    accessToken: string,
    refreshToken: string,
    refreshTokenExpiresAtEpoch: number,
}

// SALESFORCE RESPONSES from backend
export interface SalesforceInstances {
    instanceUrl: string,
    lastUsed: Date,
}

// QUERY RESPONSES
export interface RecordObject {
    attributes: {
        type: string,
        url: string,
    },
    QualifiedApiName: string,
}

export interface RecordObjectField {
    attributes: {
        type: string,
        url: string,
    },
    EntityDefinition: {
        attributes: {
            type: string,
            url: string,
        },
        QualifiedApiName: string,
    },
    QualifiedApiName: string,
    DataType: string,
    NamespacePrefix: string | null,
    MasterLabel: string,
    Label: string,
    IsNillable: boolean,
    Description: string | null,
}

export interface QueryGetObjectsResponse<Type> {
    totalSize: number,
    done: boolean,
    records: Type[],
}

// DASHBOARD TYPES
export interface ObjectSelection {
    name: string,
    type: SalesforceObjectType,
    checked: boolean,
    fields: FieldSelection[],
}

export type SalesforceObjectType = "all" | "standard" | "custom"
export type SalesforceFieldType = "required" | "custom"

export interface FieldSelection {
    name: string,
    type: SalesforceFieldType,
    checked: boolean,
    dataType: RecordObjectField["DataType"],
    isNullable: RecordObjectField["IsNillable"],
    description: RecordObjectField["Description"],
}

// PRICING
export interface SubscriptionState {
    currentPlan: string,
    internalId: string,
}