import {GlobalStates} from "../../config/global";
import {useEffect} from "react";
import {ConfettiService} from "../../confetti/ConfettiService";
import {Typography} from "@mui/material";
import {GAdsService} from "../../events-trackers/gAds-events";
import {subscriptionStatus} from "./subscriptionClient";

const getPrice = (plan: string) => {
    switch (plan.toLowerCase()) {
        case "free":
            return 0
        case "solo":
            return 49.0
        case "freelance":
            return 149.0
        case "enterprise":
            return 299.0
    }
}

export function SubSuccessful(props: {
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
}) {
    const {signInState, notificationState} = props
    const [signIn] = signInState

    useEffect(() => {
        document.title = "Subscription successful 🎊"
        ConfettiService.newSubscription()
    }, [])

    useEffect(() => {
        (signIn != null) && subscriptionStatus(signInState, notificationState)
            .then(value => {
                const currentPlan = (value != null) && value?.currentPlan
                const price = (typeof(currentPlan) === 'string') ? getPrice(currentPlan) : null
                return price
                })
            .then(price => (typeof(price) === 'number') ? GAdsService.subscribed(price) : null);
    }, [signIn])

    return (
        <>
            <Typography>Thank you for subscribing to our page!</Typography>
            <br></br>
            <Typography>You're now part of an exclusive community of developers gaining valuable insights and
                expertise.</Typography>
            <br></br>
            <Typography>Get ready to receive in-depth articles, tutorials, and tips on Salesforce data management,
                Apex to Java/Kotlin conversion, and more.</Typography>
            <br></br>
            <Typography>Stay ahead of the curve with our industry updates and be the first to know about new features
                and tools to boost your development workflow.</Typography>
            <br></br>
            <Typography>Welcome aboard and enjoy the journey!</Typography>
        </>
    )
}