import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import {CookieService} from "./cookiesService";
import {SnackbarContent} from "@mui/material";

export function CookieConsentBar() {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        // Check if the user has previously accepted cookies
        const acceptedCookies = CookieService.isConsentAccepted()

        if (!acceptedCookies) {
            setOpen(true);
        }
    }, []);

    const handleAccept = () => {
        // Store the user's preference
        CookieService.acceptCookieConsent()
        setOpen(false);
    };

    return (
        <Snackbar
            anchorOrigin={{vertical: "bottom", horizontal: "center"}}
            open={open}
        >
            <SnackbarContent
                message="We use cookies to enhance your experience."
                action={
                    <Button size="small" onClick={handleAccept}>
                        Accept
                    </Button>
                }
            />
        </Snackbar>
    );
}
