import {QueryGetObjectsResponse, RecordObject, RecordObjectField, SalesforceObjectType} from "../salesforce/types";
import demoObjects from "./objects-demo-all.json"
import objectsAllFields from "./fields-all.json"

function extractObjects(objects: QueryGetObjectsResponse<RecordObject>, custom: boolean) {
    let result: RecordObject[]
    if (custom) {
        result = objects.records.filter(value => value.QualifiedApiName.endsWith("__c"))
    } else result = objects.records.filter(value => !value.QualifiedApiName.endsWith("__c"))

    return {
        ...objects,
        totalSize: result.length,
        records: result,
    }
}

export const mockedObjects = async (type: SalesforceObjectType): Promise<string[]> => {
    let objects: QueryGetObjectsResponse<RecordObject>
    switch (type) {
        case "all": objects = demoObjects; break
        case "custom": objects = extractObjects(demoObjects, true); break
        case "standard": objects = extractObjects(demoObjects, false); break
    }

    return objects.records.map(value => value.QualifiedApiName)
}

export const mockedFields = async (objects: string[]): Promise<RecordObjectField[]> => {
    return (objectsAllFields as RecordObjectField[])
        .filter(value => objects.includes(value.EntityDefinition.QualifiedApiName))
}