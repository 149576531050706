import {GlobalStates} from "../../config/global";
import React, {useEffect, useState} from "react";
import {PricingTable} from "./PricingTable";
import {subscriptionStatus} from "./subscriptionClient";
import {SubscriptionState} from "../../salesforce/types";

export function PricingPage(props: {
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
}) {
    const {signInState, notificationState} = props
    const [signIn] = signInState

    const subscriptionState = useState<SubscriptionState>()
    const [, setSubscriptionState] = subscriptionState

    useEffect(() => {
        if (signIn != null) subscriptionStatus(signInState, notificationState)
            .then(value => {
                if (value != null) setSubscriptionState({
                    currentPlan: value?.currentPlan,
                    internalId: value?.internalId,
                })
            })
    }, [signIn])

    useEffect(() => {document.title = "Subscription"}, [])

    return (
        <>
            <PricingTable
                signInState={signInState}
                subscriptionState={subscriptionState}
                notificationState={notificationState}
            />
        </>
    )
}