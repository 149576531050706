import {Button} from "@mui/material";

export const SubscribeButton = (props: {
    subscribeLink: string,
    isDisabled?: boolean,
    text?: string,
}) =>
    <Button
        variant={"contained"}
        disabled={props.isDisabled == true}
        href={props.subscribeLink}>
        {props.text ? props.text : "Subscribe"}
    </Button>

export const ActiveButton = () =>
    <Button
        variant={"contained"}
        color={"success"}
    >
        {"Active"}
    </Button>