import axios from "axios";
import {GlobalStates} from "../config/global";
import {pushErrorToNotification} from "../generator/backendClient";

/**
 * Try to load list of gists in max size 1.
 *
 * @param token
 * @param notificationState
 */
export const verifyGistToken = async (
    token: string,
    notificationState: GlobalStates["notificationState"],
): Promise<boolean | undefined> => {

    return axios({
        baseURL: "https://api.github.com",
        url: "/gists",
        method: "GET",
        headers: {
            Accept: 'application/json',
            Authorization: `token ${token}`,
        },
        params: {
            per_page: 1,
            page: 1,
        }
    })
        .then(value => Object.keys(value).length !== 0)
        .catch(reason => pushErrorToNotification(reason, notificationState))
}

/**
 * Try to load existing gist with given ID
 *
 * @param token
 * @param id existing Gist ID
 * @param notificationState
 */
export const verifyGistId = async (
    id: string,
    token: string,
    notificationState: GlobalStates["notificationState"],
): Promise<boolean | undefined> => {

    return axios({
        baseURL: "https://api.github.com",
        url: `/gists/${id}`,
        method: "GET",
        headers: {
            Accept: 'application/json',
            Authorization: `token ${token}`,
        },
    })
        .then(value => Object.keys(value).length !== 0)
        .catch(reason => pushErrorToNotification(reason, notificationState))
}