import {GlobalStates} from "../config/global";
import axios from "axios";
import {SignInState} from "../salesforce/types";
import {ServerEnv} from "../config/envVariables";
import {pushErrorToNotification} from "../generator/backendClient";
import {CookieService} from "../cookies/cookiesService";

export const sendGoogleSignInCodeToBackend = (
    code: string,
    notificationState: GlobalStates["notificationState"],
): Promise<SignInState | undefined> => {

    CookieService.removeRefreshCookies()

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/auth/google",
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data: { code },
        withCredentials: true,
    })
        .then(value => {
            let result = value.data as SignInState
            return result
        })
        .catch(reason => pushErrorToNotification(reason, notificationState))
}

export const refreshAccessToken = (
    notificationState: GlobalStates["notificationState"],
): Promise<SignInState | undefined> => {

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/auth/refresh",
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    })
        .then(value => {
            let result = value.data as SignInState
            return result
        })
        .catch(reason => pushErrorToNotification(reason, notificationState))
}