import React, {FC, useEffect, useState} from 'react';
import LeftDrawer from "./left-menu/LeftDrawer";
import {Alert, Container, createTheme, CssBaseline, Grid, Snackbar, Theme, ThemeProvider} from "@mui/material";
import {SalesforceLoginStatus} from "./salesforce/components/SalesforceLoginStatus";
import {Generator} from "./salesforce/components/Generator";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {RouterLinks} from "./config/RouterLinks";
import {ObjectSelection, SalesforceLoginResponse, SignInState} from "./salesforce/types";
import {Notification} from "./types";
import {Gist} from "./generator/responses";
import {SignInPage} from "./sign-in/SignInPage";
import {validateAuthToken} from "./auth/refreshService";
import {PricingPage} from "./subscription/components/PricingPage";
import {CookieService} from "./cookies/cookiesService";
import {SubSuccessful} from "./subscription/components/SubSuccessful";
import {CookieConsentBar} from "./cookies/CookieConsentBar";
import {CustomTheme} from "./theme";
import {Landing} from "./landing-page/Landing";

interface GridContentProps {
    children: React.ReactNode,
    theme: Theme,
}

const MainGrid: FC<Pick<GridContentProps, "children">> = (props) => (<Container><Grid
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="stretch"
>
    {props.children}
</Grid></Container>)
const ContentGrid: FC<GridContentProps> = (props) => (<Grid
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="stretch"
    maxWidth={900}
    paddingTop="15px"
    paddingBottom={"300px"}
    paddingLeft={`calc(${props.theme.spacing(7)} + 1px)`}
    wrap={"nowrap"}
>
    {props.children}
</Grid>)

const ColorModeContext = React.createContext({
    toggleColorMode: () => {
    }
})

function App() {
    // Toggle Color
    const [mode, setMode] = React.useState<'light' | 'dark'>('light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    )

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode,
                },
                ...(mode === 'light' ? CustomTheme.customLightTheme : CustomTheme.customDarkTheme),
            }),
        [mode],
    )

    // Listen to system theme changes
    useEffect(() => {
        // Add listener to update styles
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => setMode(e.matches ? 'dark' : 'light'));

        // Setup dark/light mode for the first time
        setMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

        // Remove listener
        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, [])

    // Left Drawer
    const leftDrawerOpenState = useState(false);

    // Salesforce Login
    const salesforceAuth = useState<SalesforceLoginResponse>()
    const [, setSalesforceAuth] = salesforceAuth

    // Sign In
    const signInState = useState<SignInState>()

    // Generator
    const objectsState = useState<ObjectSelection[]>([])
    const generatedObjectGistsState = useState<Gist | null>(null)
    const generatedUseCasesGistsState = useState<Gist | null>(null)

    // Notification
    const notificationState = useState<Notification | undefined>()
    const [notification, setNotification] = notificationState

    useEffect(() => {
        validateAuthToken({notificationState, signInState})
            .then(value => {
                if (value != null) {
                    setSalesforceAuth(CookieService.getSalesforceLogin())
                }
            })
    }, [])

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>

                {/*<Container>*/}
                <MainGrid>
                    <BrowserRouter>
                        <LeftDrawer
                            isOpenState={leftDrawerOpenState}
                            themeToggleProps={{ColorModeContext}}
                            signInState={signInState}
                        />
                        <ContentGrid theme={theme}>
                            <Routes>
                                <Route path={"/"} element={<Landing/>}/>
                                <Route path={RouterLinks.generator} element={
                                    <Generator
                                        salesforceAuthState={salesforceAuth}
                                        notificationState={notificationState}
                                        objectsState={objectsState}
                                        signInState={signInState}
                                        gistObjectsState={generatedObjectGistsState}
                                        gistUseCasesState={generatedUseCasesGistsState}
                                    />
                                }/>
                                <Route path={RouterLinks.salesforce}>
                                    <Route path={RouterLinks.status} element={
                                        <SalesforceLoginStatus
                                            salesforceAuthState={salesforceAuth}
                                            signInState={signInState}
                                            notificationState={notificationState}
                                        />
                                    }/>
                                </Route>
                                <Route path={RouterLinks.signIn} element={
                                    <SignInPage
                                        notificationState={notificationState}
                                        signInState={signInState}
                                    />
                                }/>
                                <Route path={RouterLinks.subscription}>
                                    <Route index element={
                                        <PricingPage
                                            notificationState={notificationState}
                                            signInState={signInState}
                                        />
                                    }/>
                                    <Route path={RouterLinks.success} element={
                                        <SubSuccessful
                                            notificationState={notificationState}
                                            signInState={signInState}
                                        />
                                    }/>
                                </Route>
                            </Routes>
                        </ContentGrid>
                    </BrowserRouter>
                </MainGrid>
                {/*</Container>*/}

                <CookieConsentBar/>

                <Snackbar
                    open={notification !== undefined}
                    onClose={() => setNotification(undefined)}
                    key='notification-snackbar'
                    anchorOrigin={{vertical: "top", horizontal: "right"}}
                >
                    <Alert severity={notification?.severity}>{notification?.message}</Alert>
                </Snackbar>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
        ;
}

export default App;
