import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    FormLabel,
    Modal,
    Radio,
    RadioGroup, Tooltip
} from "@mui/material";
import React, {useState} from "react";
import {GlobalStates} from "../../../../config/global";
import {Settings} from "@mui/icons-material";
import {ModalUtils} from "./ModalUtils";
import {GeneratorOptions} from "../GenerateCodeWrapper";

export function SettingsModal(props: {
    generatorOptionsState: [(GeneratorOptions), (value: (((prevState: GeneratorOptions) => GeneratorOptions) | GeneratorOptions)) => void],
    notificationState: GlobalStates["notificationState"],
    generateActionsDisabled: boolean,
    isDemo: boolean,
}) {
    const {
        generatorOptionsState,
        notificationState,
        generateActionsDisabled,
        isDemo
    } = props

    const [generatorOptions, setGeneratorOptions] = generatorOptionsState

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const buttonDisabled = () => generateActionsDisabled || isDemo
    const unavailableTooltipMsg = "Settings are unavailable in Demo"

    const languageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGeneratorOptions(prevState => ({
            ...prevState,
            language: event.target.value as "JAVA" | "KOTLIN",
        }))
    }

    return (
        <>
            <Tooltip
                title={buttonDisabled() ? unavailableTooltipMsg : ""}
                arrow>
                <div style={{ display: 'inline-block' }}>
                    <Button
                        disabled={buttonDisabled()}
                        onClick={handleOpen}
                        startIcon={<Settings/>}
                    >Output</Button>
                </div>
            </Tooltip>
            <Modal
                sx={ModalUtils.modalStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {/*Generator option*/}
                <Box sx={ModalUtils.boxStyle}>
                    {/*Select Language*/}
                    <FormControl component="fieldset">
                        <FormLabel component="legend">Output</FormLabel>
                        <RadioGroup
                            aria-label="language selection"
                            name="languageSelection"
                            value={generatorOptions.language}
                            onChange={languageChangeHandler}
                        >
                            <FormControlLabel value="JAVA" control={<Radio />} label="Java" />
                            <FormControlLabel value="KOTLIN" control={<Radio />} label="Kotlin" />
                        </RadioGroup>
                    </FormControl>
                </Box>
            </Modal>
        </>
    )
}