import React, {useMemo} from "react";
import {Gist as GistType} from "../../generator/responses";
import {GistAccordion} from "./GistAccordion";
import {GlobalStates} from "../../config/global";
import {Divider, Grid} from "@mui/material";
import {OpenInGitHubIconButton} from "./OpenInGitHubIconButton";

const namesByPriority = [
    "SObjectType",
    "SObjectInterface",
    "SObjectAttributes",
    "AbstractSObject",
    "AuthRequest",
    "AuthResponse",
    "SalesforceAuth",
    "SfResponse",
    "SalesforceClient",
]

const getPriority = (fileName: string) =>
    namesByPriority.findIndex(priorityName => fileName.startsWith(priorityName)) + 1 || namesByPriority.length + 1

export function GistAccordions(props: {
    gist: GistType,
    notificationState: GlobalStates["notificationState"],
}) {
    const {gist, notificationState} = props
    const filenames = gist.filenames
    filenames.sort((a, b) => getPriority(a) - getPriority(b))

    const accordions = filenames.filter(value => (value !== undefined)).map(file => {
        const uniqueKey = `${gist.id}-${file!}`
        return <GistAccordion
            key={uniqueKey}
            uniqueKey={uniqueKey}
            gistId={gist.id}
            filename={file!}
            notificationState={notificationState}
        />
    })
    return (
        <>
            {accordions}
        </>
    )
}

export function GistCategoryAccordions(props: {
    gist: GlobalStates["gistState"][0],
    categoryName: string,
    notificationState: GlobalStates["notificationState"],
}) {
    const {gist, categoryName, notificationState} = props

    return useMemo(() => {
        if (gist?.filenames?.length) {
            return <>
                <Divider textAlign="left"><OpenInGitHubIconButton gist={gist}/>{categoryName}</Divider>
                {/*<IconButton*/}
                {/*    aria-label="copy all"*/}
                {/*    onClick={() => copyAllFiles(notificationState[1])}>*/}
                {/*    <CopyAll aria-label={"copy all"}>Copy All</CopyAll>*/}
                {/*</IconButton>*/}
                <Grid item xs={12} paddingBottom={"50px"}>
                    <GistAccordions gist={gist} notificationState={notificationState}></GistAccordions>
                </Grid>
            </>
        }
        return null
    }, [gist])
}