import axios from "axios";
import {GlobalStates} from "../../config/global";
import {pushErrorToNotification} from "../../generator/backendClient";
import {ServerEnv} from "../../config/envVariables";
import {validateAuthToken} from "../../auth/refreshService";
import {SubscriptionState} from "../../salesforce/types";

/**
 * Get Pricing State
 *
 * @param signInState
 * @param notificationState
 */
export const subscriptionStatus = async (
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<SubscriptionState | undefined> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/subscription/state",
        method: "GET",
        headers: {
            Accept: 'application/json',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
        .then(value => value.data as SubscriptionState)
        .catch(reason => pushErrorToNotification(reason, notificationState))
}

export const unsubscribe = async (
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<boolean | undefined> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/subscription/unsubscribe",
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
    })
        .then(_ => {
            notificationState[1]({
                severity: "info",
                message: JSON.stringify("You have been unsubscribed"),
            })
            return true
        })
        .catch(reason => pushErrorToNotification(reason, notificationState))
}