import {GRANT_TYPE} from "../salesforce/types";
import {assertAllValuesAreDefined} from "./assertions";

const DOMAIN = process.env.REACT_APP_DOMAIN as string
const BE_URL = process.env.REACT_APP_BE_URL as string
const FE_URL = process.env.REACT_APP_FE_URL as string

export const ServerEnv = {
    domain: DOMAIN,
    backendUrl: BE_URL,
    frontendUrl: FE_URL,
}
assertAllValuesAreDefined(ServerEnv)

const CLIENT_ID = process.env.REACT_APP_SF_CLIENT_ID as string
const CLIENT_SECRET = process.env.REACT_APP_SF_CLIENT_SECRET as string

export const SfAppCredentialsEnv = {
    grant_type: GRANT_TYPE.PASSWORD,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
}
assertAllValuesAreDefined(SfAppCredentialsEnv)

const GOOGLE_ANALYTICS_4 = process.env.REACT_APP_GOOGLE_ANALYTICS_4 as string

export const GoogleAnalytics4 = {
    measurementId: GOOGLE_ANALYTICS_4,
}
assertAllValuesAreDefined(GoogleAnalytics4)

const GOOGLE_ADS_ID = (process.env.REACT_APP_ADS_ID || 'AW-11224988296') as string
const GOOGLE_ADS_SUBSCRIBE_LABEL = process.env.REACT_APP_ADS_SUBS_LABEL || 'ZXNkCNSewq0YEIj1vugp' as string

export const GoogleAds = {
    conversionId: GOOGLE_ADS_ID,
    subscribeLabel: GOOGLE_ADS_SUBSCRIBE_LABEL,
}
assertAllValuesAreDefined(GoogleAds)

const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GAUTH_CLIENT_ID as string

export const GoogleAuth = {
    clientId: GOOGLE_AUTH_CLIENT_ID,
}
assertAllValuesAreDefined(GoogleAuth)

const STRIPE_LINK_ENTERPRISE = process.env.REACT_APP_STRIPE_LINK_ENTERPRISE as string
const STRIPE_LINK_FREELANCE = process.env.REACT_APP_STRIPE_LINK_FREELANCE as string
const STRIPE_LINK_SOLO = process.env.REACT_APP_STRIPE_LINK_SOLO as string

export const Stripe = {
    paymentLinkEnterprise: STRIPE_LINK_ENTERPRISE,
    paymentLinkFreelance: STRIPE_LINK_FREELANCE,
    paymentLinkSolo: STRIPE_LINK_SOLO,
}
assertAllValuesAreDefined(Stripe)