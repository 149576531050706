import {GlobalStates} from "../config/global";
import {Grid} from "@mui/material";
import React, {useEffect} from "react";
import {GoogleSignInButton} from "./GoogleSignInButton";
import {GoogleOAuthProvider} from "@react-oauth/google";
import {SignOutButton} from "./SignOutButton";
import {GoogleAuth} from "../config/envVariables";

export function SignInPage(props: {
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
}) {
    const [signIn] = props.signInState

    const status = signIn ?
        <>
            <p>{`${signIn.email} is logged in`}</p>
            <SignOutButton signInState={props.signInState}/>
        </> :
        <>
            <GoogleOAuthProvider clientId={GoogleAuth.clientId}>
                <GoogleSignInButton signInState={props.signInState} notificationState={props.notificationState}/>
            </GoogleOAuthProvider>
            <p>Sign In with GitHub Button</p>
        </>

    useEffect(() => {document.title = "Sign In"}, [])

    return (
        <>
            <Grid item xs={3} paddingBottom={"30px"}>
                {status}
            </Grid>
        </>
    )
}