import {CookieNames, GlobalStates} from "../config/global";
import {Button} from "@mui/material";
import Cookies from "js-cookie";
import {GAnalyticsEvents} from "../events-trackers/gAnalytics-events";

export function SignOutButton(props: {
    signInState: GlobalStates["signInState"],
}) {
    const [, setSignIn] = props.signInState

    const onSignOut = async () => {
        setSignIn(undefined)
        Cookies.remove(CookieNames.refreshTokenExpiresAt)
        GAnalyticsEvents.eventGmailLogout()
    }

    return (
        <Button variant="contained" color="primary" onClick={onSignOut}>Sign out</Button>
    )
}