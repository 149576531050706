import "./styles/synthwave-84.css"
import "./styles/codemirror.min.css"
import "./styles/jsx-4023755556.css"
import "./styles/jsx-1170653115.css"
import "./styles/jsx-1831890883.css"
import "./styles/jsx-43165554.css"

const carbonCopy = <div id="export-container" className="export-container">
    <div className="jsx-1831890883 container" style={{minWidth: 629}}>
        <div className="jsx-4023755556 window-controls">
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14">
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" strokeWidth=".5"></circle>
                    <circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" strokeWidth=".5"></circle>
                    <circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" strokeWidth=".5"></circle>
                </g>
            </svg>
            <div className="jsx-1170653115"><input aria-label="Image title" type="text" spellCheck="false"
                                                   className="jsx-1170653115" value="" readOnly/></div>
        </div>
        <div className="react-codemirror2 CodeMirror__container window-theme__none">
            <div className="CodeMirror CodeMirror-wrap cm-s-synthwave-84" translate="no">
                <div
                    style={{
                        overflow: "hidden",
                        position: "relative",
                        width: 3,
                        height: 0,
                        top: 387.16,
                        left: 496.477,
                    }}>
                    <textarea autoCorrect="off" autoCapitalize="off" spellCheck="false" aria-label="Code editor"
                              tabIndex={0}
                              style={{
                                  position: "absolute",
                                  bottom: "-1em",
                                  padding: 0,
                                  width: 1000,
                                  height: "1em",
                                  minHeight: "1em",
                                  outline: "none",
                              }}></textarea>
                </div>
                <div className="CodeMirror-scrollbar-filler" cm-not-content="true"></div>
                <div className="CodeMirror-gutter-filler" cm-not-content="true"></div>
                <div className="CodeMirror-scroll" tabIndex={-1} draggable="false">
                    <div className="CodeMirror-sizer"
                         style={{
                             marginLeft: 0,
                             marginBottom: 0,
                             borderRightWidth: 50,
                             paddingRight: 0,
                             paddingBottom: 0,
                         }}>
                        <div style={{position: "relative", top: 0,}}>
                            <div className="CodeMirror-lines" role="presentation">
                                <div role="presentation" style={{position: "relative", outline: "none",}}>
                                    <div className="CodeMirror-measure">
                                        <pre className="CodeMirror-line-like"><span>xxxxxxxxxx</span></pre>
                                    </div>
                                    <div className="CodeMirror-measure"></div>
                                    <div style={{position: "relative", zIndex: 1,}}></div>
                                    <div className="CodeMirror-cursors" style={{visibility: "hidden",}}>
                                        <div className="CodeMirror-cursor"
                                             style={{left: 484.477, top: 335.16, height: 18.6172,}}>&nbsp,
                                        </div>
                                    </div>
                                    <div className="CodeMirror-code" role="presentation"
                                         style={{textRendering: "auto",}}>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}><span
                                            className="cm-keyword">class</span> <span className="cm-def">Account</span> (</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"Active__c"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">active__c</span>: <span
                                                className="cm-type">String</span><span className="cm-operator">?</span> <span
                                                className="cm-operator">=</span> <span className="cm-atom">null</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"AnnualRevenue"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">annualRevenue</span>: <span
                                                className="cm-type">Double</span><span className="cm-operator">?</span> <span
                                                className="cm-operator">=</span> <span className="cm-atom">null</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"CreatedById"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">createdById</span>: <span
                                                className="cm-type">String</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"CreatedDate"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">createdDate</span>: <span
                                                className="cm-type">ZonedDateTime</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"Description"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">description</span>: <span
                                                className="cm-type">String</span><span className="cm-operator">?</span> <span
                                                className="cm-operator">=</span> <span className="cm-atom">null</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"IsDeleted"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">isDeleted</span>: <span
                                                className="cm-type">Boolean</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"Name"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">name</span>: <span className="cm-type">String</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"Phone"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">phone</span>: <span className="cm-type">String</span><span
                                                className="cm-operator">?</span> <span className="cm-operator">=</span> <span
                                                className="cm-atom">null</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}> &nbsp; &nbsp;
                                            <span className="cm-meta">@JsonProperty</span>(<span
                                                className="cm-string">"Website"</span>) <span
                                                className="cm-keyword">val</span> <span
                                                className="cm-def">website</span>: <span
                                                className="cm-type">String</span><span className="cm-operator">?</span> <span
                                                className="cm-operator">=</span> <span className="cm-atom">null</span>,</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}>) : <span
                                            className="cm-variable">AbstractSObject</span><span
                                            className="cm-operator">&lt;</span><span
                                            className="cm-variable">SObjectType</span><span
                                            className="cm-operator">&gt;</span>(<span
                                            className="cm-variable">type</span> <span
                                            className="cm-operator">=</span> <span
                                            className="cm-variable">SObjectType</span>.<span
                                            className="cm-variable">ACCOUNT</span>)</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}><span
                                            cm-text="">​</span></span></pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            height: 50,
                            width: 1,
                            borderBottom: "0px solid transparent",
                            top: 399,
                        }}></div>
                    <div className="CodeMirror-gutters" style={{display: "none", height: 449,}}></div>
                </div>
            </div>
        </div>
    </div>
</div>

export function KotlinSnippet() {
    return (carbonCopy)
}