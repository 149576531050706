import {Accordion, AccordionDetails, AccordionSummary, Box, IconButton} from "@mui/material";
import {ContentCopy, ExpandMore, SyncProblem} from "@mui/icons-material";
import React, {useEffect, useState} from "react";
import Gist from "react-gist";
import {GlobalStates} from "../../config/global";
import {copyFile} from "../copyUtils";

export function GistAccordion(props: {
    gistId: string,
    uniqueKey: string,
    filename: string,
    notificationState: GlobalStates["notificationState"],
}) {
    const {gistId, filename, uniqueKey, notificationState} = props
    const panelId = `panel-${uniqueKey}`

    const [, setNotification] = notificationState

    const iframeId = `gist-${gistId}-${filename}`
    const [gistUniqueKey, setGistUniqueKey] = useState(`${uniqueKey}-gist`)
    const [gistLoadError, setGistLoadError] = useState(false)

    const copyButton = (gistId: string, filename: string) => <IconButton aria-label="copy code" color="primary">
        <ContentCopy onClick={async (event) => {
            event.stopPropagation()
            await copyFile(iframeId, setNotification)
        }}/>
    </IconButton>

    const reloadButton = () => <IconButton aria-label="reload gist" color="warning">
        <SyncProblem onClick={async (event) => {
            event.stopPropagation()
            setGistUniqueKey(prevState => prevState + "r")
        }}/>
    </IconButton>

    useEffect(() => {
        const timer = setTimeout(() => {
            const iframe = document.getElementById(iframeId) as HTMLIFrameElement
            const table = iframe?.contentDocument?.querySelector('table') as HTMLTableElement
            const isError = table == null
            setGistLoadError(isError)
        }, 2000)

        return () => clearTimeout(timer)
    }, [iframeId, gistUniqueKey])

    return (
        <Accordion key={`${uniqueKey}-accordion`}>
            <AccordionSummary
                expandIcon={<ExpandMore/>}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
            >
                <Box display="flex" alignItems="center">
                    {copyButton(gistId, filename)}
                    <Box ml={1}>
                        {filename}
                    </Box>
                </Box>
                {gistLoadError && <Box display="flex" alignItems="center">{reloadButton()}</Box>}
            </AccordionSummary>
            <AccordionDetails>
                <Gist key={gistUniqueKey} id={gistId} file={filename}/>
            </AccordionDetails>
        </Accordion>
    )
}