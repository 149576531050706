import {Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@mui/material";
import "./bb-pricing-style-steal.css"
import {ActiveButton, SubscribeButton} from "./SubscribeButton";
import {GlobalStates} from "../../config/global";
import {RouterLinks} from "../../config/RouterLinks";
import {Link} from "react-router-dom";
import {Stripe} from "../../config/envVariables";
import {LoadingButton} from "@mui/lab";
import React, {useCallback, useState} from "react";
import {unsubscribe} from "./subscriptionClient";

function createFeatureData(
    name: string,
    tooltip: string,
    free: string,
    solo: string,
    freelance: string,
    business: string,
) {
    return {name, tooltip, free, solo, freelance, business};
}

const featureRows = [
    createFeatureData("API Credits", "1 token = 1 salesforce class generation", "10", "100", "1000", "5000"),
    createFeatureData("Salesforce Accounts", "How many salesforce accounts you can connect", "1", "1", "10", "50"),
    createFeatureData("Your Gist Token", "If you provide your own gist token, we store generated code there", "❌", "✅", "✅", "✅"),
    createFeatureData("Gist private visibility", "Generated gist can be set as private", "❌", "✅", "✅", "✅"),
    createFeatureData("Issues Priority", "Issues reported by you will be prioritized", "❌", "❌", "✅", "✅"),
    createFeatureData("Features Priority", "Features you want will be prioritized", "❌", "❌", "✅", "✅"),
]

interface PriceData {
    name: string,
    price: string,
    desc: string,
    link: string,
    btnText?: string,
    isDisabled: boolean,
    isMostPopular?: boolean,
}

const priceRows: PriceData[] = [
    {name: "Free", price: "0", desc: "You got few tokens, use them wisely", link: "#Free", isDisabled: false},
    {
        name: "Solo",
        price: "49",
        desc: "For a single business",
        link: Stripe.paymentLinkSolo,
        isDisabled: false
    },
    {
        name: "Freelance",
        price: "149",
        desc: "Salesforce is your daily job",
        link: Stripe.paymentLinkFreelance,
        isDisabled: false,
        isMostPopular: true
    },
    {
        name: "Enterprise",
        price: "299",
        desc: "You're going to the Moon",
        link: Stripe.paymentLinkEnterprise,
        isDisabled: false,
    },
]

export function PricingTable(props: {
    signInState: GlobalStates["signInState"],
    subscriptionState: GlobalStates["subscriptionState"],
    notificationState: GlobalStates["notificationState"],
}) {
    const {signInState, subscriptionState, notificationState} = props
    const [signIn] = signInState
    const [subscription] = subscriptionState
    const linkParams = (): string => (signIn?.email != null)
        ? `?client_reference_id=${subscription?.internalId}` +
            `&prefilled_email=${signIn?.email}`
        : ""

    const [loadingUnsubscribe, setLoadingUnsubscribe] = useState(false)

    const isCurrentPlan = (s1: string): boolean => s1.toLowerCase() === subscription?.currentPlan?.toLowerCase()
    const getPlanIndex = (s1: string | undefined): number => priceRows.findIndex(value => value.name.toLowerCase() === s1?.toLowerCase())

    const handleUnsubscribeClick = useCallback(async () => {
        setLoadingUnsubscribe(true)
        await unsubscribe(signInState, notificationState)
        setLoadingUnsubscribe(false)
    }, [signInState, subscriptionState])

    const resolveButton = (row: PriceData) => {
        if (signIn == null) return <Button
            variant={"contained"}
            component={Link}
            disabled={row.isDisabled}
            to={`/${RouterLinks.signIn}`}
        >Sign-in</Button>

        if (isCurrentPlan(row.name)) return <ActiveButton/>

        const thisPlanIndex = getPlanIndex(row.name)
        const currentPlanIndex = getPlanIndex(subscription?.currentPlan)
        if (currentPlanIndex && thisPlanIndex === 0) return <LoadingButton
            loading={loadingUnsubscribe}
            color="primary"
            variant="contained"
            onClick={handleUnsubscribeClick}
        >Unsubscribe</LoadingButton>

        let btnText: string

        switch(thisPlanIndex > currentPlanIndex) {
            case true: {
                btnText = "Upgrade"; break;
            }
            case false: {
                btnText = "Downgrade"; break;
            }
            default: {
                btnText = "Subscribe"; break;
            }
        }

        return <SubscribeButton
            subscribeLink={`${row.link}${linkParams()}`}
            text={btnText}
            isDisabled={row.isDisabled}
        />
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <div>
                                <p><strong>On all plans</strong></p>
                                <p>✓ Upgrade anytime</p>
                                <p>✓ Downgrade anytime</p>
                                <p>✓ Cancel anytime</p>
                            </div>
                        </TableCell>
                        {priceRows.map(row => (
                            <TableCell
                                key={`${row.name}${row.price}`}
                                align="right"
                                className="new_pricing_table_2020_12"
                                sx={{"lineHeight": "unset"}}
                            >
                                <div className="new_price_plan plan_header">
                                    {row.isMostPopular && <div className="highlighted_title">
                                        <span>Most Popular</span>
                                    </div>}
                                    <div className="plan_name">{row.name}</div>
                                    <div className="plan_price">
                                        <span className="the_price_container">
                                            <span className="the_dollar_sign adjust">$</span>
                                            <span className="the_number">{row.price} </span>
                                        </span>
                                        <span className="per">USD / month</span>
                                    </div>
                                    <div className="plan_cta">
                                        {resolveButton(row)}
                                    </div>
                                    <div className="plan_desc">{row.desc}</div>
                                </div>
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {featureRows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <Tooltip placement={"right"} title={row.tooltip}>
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                            </Tooltip>
                            <TableCell align="center">{row.free}</TableCell>
                            <TableCell align="center">{row.solo}</TableCell>
                            <TableCell align="center">{row.freelance}</TableCell>
                            <TableCell align="center">{row.business}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}