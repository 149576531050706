import {ThemeToggle, ThemeToggleProps} from "./ThemeToggle";
import MuiDrawer from '@mui/material/Drawer';
import {Box, CSSObject, Divider, IconButton, List, ListItem, styled, Theme} from "@mui/material";
import {
    ChevronLeft,
    ChevronRight,
    DataObject,
    HowToVote,
    Login,
    Loyalty,
    ManageAccounts,
    OndemandVideo,
    RequestPage
} from "@mui/icons-material";
import React, {useMemo} from "react";
import {RouterLinks} from "../config/RouterLinks";
import {ListItemLink} from "./ListItemLink";
import {GlobalStates} from "../config/global";
import {ListItemCredits} from "./ListItemCredits";
import CgLogo from '../logo.svg'

const openedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(7)} + 1px)`,
    },
});

const DrawerHeader = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

export default function LeftDrawer(props: {
    signInState: GlobalStates["signInState"],
    isOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>],
    themeToggleProps: ThemeToggleProps,
}) {
    const {signInState, isOpenState, themeToggleProps} = props
    const [isOpen, setIsOpen] = isOpenState

    const toggleDrawer = (toggle: boolean) => (event: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent<HTMLDivElement>).key === 'Tab' || (event as React.KeyboardEvent<HTMLDivElement>).key === 'Shift')
        ) {
            return
        }

        setIsOpen(toggle)
    }

    const colorMode = React.useContext(themeToggleProps.ColorModeContext)

    const listItems = useMemo(() => [
        {
            to: "/",
            primary: "Intro",
            icon: <OndemandVideo/>,
            disabled: false,
            open: isOpen,
        },
        {
            to: `/${RouterLinks.generator}`,
            primary: "Generator",
            icon: <DataObject/>,
            disabled: false,
            open: isOpen,
        },
        {
            to: `/${RouterLinks.signIn}`,
            primary: "Sign In",
            icon: <Login/>,
            disabled: false,
            open: isOpen,
        },
        {
            to: `/${RouterLinks.salesforce}/${RouterLinks.status}`,
            primary: "Salesforce manager",
            icon: <ManageAccounts/>,
            disabled: signInState[0] == null,
            open: isOpen,
        },
        {
            to: `/${RouterLinks.subscription}`,
            primary: "Subscription",
            icon: <RequestPage/>,
            disabled: false,
            open: isOpen,
        },
        {
            to: "https://salesforce-codegen.canny.io/feature-requests",
            primary: "Feature requests",
            icon: <HowToVote/>,
            disabled: false,
            open: isOpen,
        },
    ]
        .map((listItemLinkProps, index) => <ListItemLink
            key={index} {...listItemLinkProps}
        />), [isOpen, signInState])

    const list = useMemo(() => (
        <div role="presentation">
            <List>
                {listItems}
            </List>
            <List style={{bottom: 0, position: "absolute"}}>
                <li>
                    <ListItem button key="Theme toggle" onClick={colorMode.toggleColorMode} color="inherit">
                        <ThemeToggle ColorModeContext={themeToggleProps.ColorModeContext} open={isOpen}/>
                    </ListItem>
                </li>
                <ListItemCredits
                    primary="Created by Vergil333"
                    href="https://martinmachava.com/?utm_source=salesforce-codegen&utm_medium=website&utm_campaign=martinmachava"
                    icon={<Loyalty/>}
                    open={isOpen}
                />
            </List>
        </div>
    ), [colorMode, signInState])

    return (
        <Box sx={{display: 'flex'}}>
            <Drawer
                anchor="left"
                variant="permanent"
                open={isOpen}
                onClose={toggleDrawer(false)}
            >
                <DrawerHeader>
                    <div
                        onClick={toggleDrawer(!isOpen)}
                        onKeyDown={toggleDrawer(!isOpen)}
                    >
                        {isOpen ? <img
                            src={CgLogo}
                            alt="CodeGen logo"
                            style={{
                                "position": "absolute",
                                "height": "64px",
                                "left": "15px",
                                "top": "0px",
                            }}
                        /> : null}
                        <IconButton>
                            {isOpen ? <ChevronLeft/> : <ChevronRight/>}
                        </IconButton>
                    </div>
                </DrawerHeader>
                <Divider/>
                {list}
            </Drawer>
        </Box>
    )
}