import {Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader} from "@mui/material";
import React from "react";
import {ObjectSelection} from "../types";

export function LoadedObjectsList(props: {
    header: string,
    objects: ObjectSelection[],
    handleObjectClick: (change: ObjectSelection) => void,
}) {
    const {header, objects, handleObjectClick} = props

    return (
        <List subheader={<ListSubheader>{header}</ListSubheader>} sx={{
            width: "100%",
            maxHeight: 500,
            overflow: "auto",
            bgcolor: "background.paper",
        }}>
            {objects.map(value => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                    <ListItem
                        key={value.name}
                        disablePadding
                    >
                        <ListItemButton role={undefined} onClick={() => handleObjectClick(value)} dense>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={value.checked}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{'aria-labelledby': labelId}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.name}/>
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    )
}