import {ObjectSelection, SalesforceLoginResponse, SignInState, SubscriptionState} from "../salesforce/types";
import {Notification} from "../types";
import {Gist} from "../generator/responses";

/**
 * setter originally was React.Dispatch<React.SetStateAction<SalesforceLoginResponse | undefined>>,
 */
export interface GlobalStates {
    // App
    salesforceAuthState: [(SalesforceLoginResponse | undefined), (newVal: (SalesforceLoginResponse | undefined)) => void],
    notificationState: [(Notification | undefined), (newVal: (Notification | undefined)) => void],
    objectsState: [(ObjectSelection[]), (newVal: (ObjectSelection[])) => void],
    signInState: [(SignInState | undefined), (newVal: (SignInState | undefined)) => void],
    gistState: [(Gist | null), (newVal: (Gist | null)) => void],

    // Pricing
    subscriptionState: [(SubscriptionState | undefined), (newVal: (SubscriptionState | undefined)) => void],
}

export const CookieNames = {
    // Cookie consent
    acceptedCookieConsent: "acceptedCookieConsent",
    // JWT
    authTokenExpiresAt: "authTokenExpiresAt",
    refreshToken: "refreshToken",
    refreshTokenExpiresAt: "refreshTokenExpiresAt",
    // Salesforce
    salesforceLogin: "salesforceLogin",
}