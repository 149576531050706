import React, {useCallback, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {GlobalStates} from "../../../config/global";
import {GeneratorRequest} from "../../../generator/requests";
import {ObjectSelection} from "../../types";
import {generateCode, generateCodeDemo} from "../../../generator/backendClient";
import {GAnalyticsEvents} from "../../../events-trackers/gAnalytics-events";

export function GenerateCodeButton(props: {
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
    gistObjectsState: GlobalStates["gistState"],
    gistUseCasesState: GlobalStates["gistState"],
    checkedObjects: ObjectSelection[],
    generateActionsDisabled: boolean,
    generatorOptions: GeneratorRequest["options"],
    isDemo: boolean,
}) {
    const [loading, setLoading] = useState(false)

    const {checkedObjects, isDemo, generateActionsDisabled, generatorOptions, notificationState, signInState} = props
    const [, setObjectsGist] = props.gistObjectsState
    const [, setUseCasesGist] = props.gistUseCasesState

    const handleButtonClick = useCallback(async () => {
        setLoading(true)

        const requestBody: GeneratorRequest = {
            objects: checkedObjects.map(value => value.name),
            options: generatorOptions,
        }
        let generatedGist = (isDemo)
            ? await generateCodeDemo(requestBody, notificationState)
            : await generateCode(requestBody, signInState, notificationState)

        setObjectsGist(generatedGist?.objects || null)
        setUseCasesGist(generatedGist?.useCases || null)

        setLoading(false)
        GAnalyticsEvents.eventGenerateCode(isDemo ? "demo" : "real")
    }, [checkedObjects, generatorOptions, isDemo, notificationState, signInState, setObjectsGist, setUseCasesGist])

    return (
        <LoadingButton
            color="primary"
            variant="contained"
            loading={loading}
            disabled={generateActionsDisabled}
            onClick={handleButtonClick}
        >Generate Code</LoadingButton>
    )
}