import "./styles/synthwave-84.css"
import "./styles/codemirror.min.css"
import "./styles/jsx-4023755556.css"
import "./styles/jsx-1170653115.css"
import "./styles/jsx-1831890883.css"
import "./styles/jsx-43165554.css"
import "./styles/scroller.css"

const carbonCopy = <div id="export-container" className="export-container">
    <div className="jsx-1831890883 container">
        <div className="jsx-4023755556 window-controls">
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14">
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" strokeWidth=".5"></circle>
                    <circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" strokeWidth=".5"></circle>
                    <circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" strokeWidth=".5"></circle>
                </g>
            </svg>
            <div className="jsx-1170653115"><input aria-label="Image title" type="text" spellCheck="false"
                                                   className="jsx-1170653115" value="" readOnly/></div>
        </div>
        <div className="react-codemirror2 CodeMirror__container window-theme__none">
            <div className="CodeMirror CodeMirror-wrap cm-s-synthwave-84 scroll-container" translate="no">
                <div className="scroll-content">
                    <div
                        style={{
                            overflow: "hidden",
                            position: "relative",
                            width: 3,
                            height: 0,
                            top: 387.16,
                            left: 496.477,
                        }}>
                    <textarea autoCorrect="off" autoCapitalize="off" spellCheck="false" aria-label="Code editor"
                              tabIndex={0}
                              style={{
                                  position: "absolute",
                                  bottom: "-1em",
                                  padding: 0,
                                  width: 1000,
                                  height: "1em",
                                  minHeight: "1em",
                                  outline: "none",
                              }}></textarea>
                    </div>
                    <div className="CodeMirror-scrollbar-filler" cm-not-content="true"></div>
                    <div className="CodeMirror-gutter-filler" cm-not-content="true"></div>
                    <div className="CodeMirror-scroll" tabIndex={-1} draggable="false">
                        <div className="CodeMirror-sizer"
                             style={{
                                 marginLeft: 0,
                                 marginBottom: 0,
                                 borderRightWidth: 50,
                                 paddingRight: 0,
                                 paddingBottom: 0,
                             }}>
                            <div style={{position: "relative", top: 0,}}>
                                <div className="CodeMirror-lines" role="presentation">
                                    <div role="presentation" style={{position: "relative", outline: "none",}}>
                                        <div className="CodeMirror-measure">
                                            <pre className="CodeMirror-line-like"><span>xxxxxxxxxx</span></pre>
                                        </div>
                                        <div className="CodeMirror-measure"></div>
                                        <div style={{position: "relative", zIndex: 1,}}></div>
                                        <div className="CodeMirror-cursors" style={{visibility: "hidden",}}>
                                            <div className="CodeMirror-cursor"
                                                 style={{left: 12.4297, top: 11264.9, height: 18.6172,}}>&nbsp;</div>
                                        </div>
                                        <div className="CodeMirror-code" role="presentation"
                                             style={{textRendering: "auto",}}>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: 0.1,}}></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp;
                                                <span className="cm-property">"totalSize"</span>: <span
                                                    className="cm-number">30</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;
                                                <span className="cm-property">"done"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;
                                                <span className="cm-property">"records"</span>: [</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCK"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Active__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Active"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Active"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCL"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"CustomerPriority__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Customer Priority"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Customer Priority"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCM"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"NumberofLocations__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Number(3, 0)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Number of Locations"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Number of Locations"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCN"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"SLA__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"SLA"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"SLA"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCO"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"SLAExpirationDate__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"SLA Expiration Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"SLA Expiration Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCP"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"SLASerialNumber__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Text(10)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"SLA Serial Number"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"SLA Serial Number"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.00N7Q000006oQCQ"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"UpsellOpportunity__c"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Upsell Opportunity"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Upsell Opportunity"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.AccountNumber"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"AccountNumber"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Text(40)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Account Number"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Account Number"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.AccountSource"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"AccountSource"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Account Source"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Account Source"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.AnnualRevenue"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"AnnualRevenue"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Currency(18, 0)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Annual Revenue"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Annual Revenue"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.BillingAddress"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"BillingAddress"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Address"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Billing Address"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Billing Address"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.CleanStatus"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"CleanStatus"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Clean Status"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Clean Status"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.CreatedBy"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"CreatedById"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Lookup(User)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Created By"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Created By"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">false</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.CreatedDate"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"CreatedDate"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Date/Time"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Created Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Created Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">false</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.DandbCompany"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"DandbCompanyId"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Lookup(D&amp;B Company)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"D&amp;B Company"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"D&amp;B Company"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.Description"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Description"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Long Text Area(32000)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Description"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Description"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.DunsNumber"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"DunsNumber"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Text(9)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"D-U-N-S Number"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"D-U-N-S Number"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.Fax"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Fax"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Fax"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Fax"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Fax"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.Id"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Id"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Lookup()"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Account ID"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Account ID"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">false</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.Industry"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Industry"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Picklist"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Industry"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Industry"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.IsDeleted"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"IsDeleted"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Checkbox"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Deleted"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Deleted"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">false</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.Jigsaw"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Jigsaw"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Text(20)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Data.com Key"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Data.com Key"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.JigsawCompany"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"JigsawCompanyId"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"External Lookup"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Jigsaw Company Id"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Jigsaw Company Id"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.LastActivityDate"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"LastActivityDate"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Last Activity"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Last Activity"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.LastModifiedBy"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"LastModifiedById"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Lookup(User)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Last Modified By"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Last Modified By"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">false</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.LastModifiedDate"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"LastModifiedDate"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Date/Time"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Last Modified Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Last Modified Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">false</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.LastReferencedDate"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"LastReferencedDate"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Date/Time"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Last Referenced Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Last Referenced Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.LastViewedDate"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"LastViewedDate"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Date/Time"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Last Viewed Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Last Viewed Date"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.MasterRecord"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"MasterRecordId"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Lookup(Account)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"Account"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"Account"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation" style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"FieldDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/FieldDefinition/Account.NaicsCode"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"EntityDefinition"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span
                                                    className="cm-property">"attributes"</span>: </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"type"</span>: <span
                                                    className="cm-string">"EntityDefinition"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"url"</span>: <span
                                                    className="cm-string">"/services/data/v53.0/sobjects/EntityDefinition/Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"Account"</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"QualifiedApiName"</span>: <span
                                                    className="cm-string">"NaicsCode"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"DataType"</span>: <span
                                                    className="cm-string">"Text(8)"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"NamespacePrefix"</span>: <span
                                                    className="cm-atom">null</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"MasterLabel"</span>: <span
                                                    className="cm-string">"NAICS Code"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Label"</span>: <span
                                                    className="cm-string">"NAICS Code"</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"IsNillable"</span>: <span
                                                    className="cm-atom">true</span>,</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp; &nbsp; &nbsp;
                                                <span className="cm-property">"Description"</span>: <span
                                                    className="cm-atom">null</span></span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}> &nbsp;  </span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}>  ]</span></pre>
                                            <pre className=" CodeMirror-line " role="presentation"><span
                                                role="presentation"
                                                style={{paddingRight: 0.1,}}></span></pre>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                position: "absolute",
                                height: 50,
                                width: 1,
                                borderBottom: "0px solid transparent",
                                top: 11292,
                            }}></div>
                        <div className="CodeMirror-gutters" style={{display: "none", height: 11342,}}></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

export function JsonSnippet() {
    return (carbonCopy)
}