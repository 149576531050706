import {useTheme} from "@mui/material";
import React from "react";

export function FancyArrow(props: {styles: React.CSSProperties | undefined}) {
    const {styles} = props
    const theme = useTheme()
    const primaryColor = theme.palette.primary.main

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="387.878" height="216.414" viewBox="0 0 387.878 216.414" style={styles}>
            <g>
                <path fill={primaryColor}
                      d="M336.738,41.644C289.8,25.773,238.017,36.6,196.683,62.528,176.352,11.1,116.874,4.4,74.468,33.11,40.41,54.5,16.937,90.286,7.75,129.167,1.956,150.233-.972,172.037.29,193.893c.742,2.7-1.689,8.956,2.721,8.743,5.481-1.8,1.146-14.32,2.2-19.332-.375-19.025,3.535-37.823,8.69-56.047C23.314,91.752,45.329,59.315,76.75,39.819c40.074-26.57,93.438-20.713,112.07,27.953-42.211,26.2-96.692,120.245-30.7,148.161,72.056,8.665,58.539-102.068,42.217-144.754C240.793,46.055,289.671,37.473,336.738,41.644ZM171.53,206.475c-20.695.819-35.478-22.39-33.082-41.522,4.386-35.37,24.837-68.019,53.939-88.4C203.241,110.076,219.891,197.055,171.53,206.475Z"/>
                <path fill={primaryColor}
                      d="M305.035,1.592c-1.647-2.555-6.806-2-7.142,1.4,1.444,5.906,17.421,7.8,22.983,10.349,20.336,5.285,40.074,12.8,56.175,26.684-16.419,15.36-31.8,31.887-43.443,51.233a.009.009,0,0,0,0,.015c11.9-11.24,23.767-22.538,36.346-33.031,6.071-6.279,16.5-11.081,17.919-20.343C370.062,12.108,332.389,11.909,305.035,1.592Z"/>
            </g>
        </svg>
    )
}