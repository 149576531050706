import React from "react";
import "./styles/cards-on-hand.css"
import {QueryAllSnippet} from "./QueryAllSnippet";
import {QueryWhereSnippet} from "./QueryWhereSnippet";

//https://codepen.io/walpolea/pen/Baawevv
export function OverlappingCards() {
    return (
        <div className="hand">
            {/*when adding more children don't forget to update repeat(2, 32px)*/}
            <div className="card"><QueryAllSnippet/></div>
            <div className="card"><QueryWhereSnippet/></div>
        </div>
    )
}