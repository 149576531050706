import {Button} from "@mui/material";
import React from "react";
import {connectedAppOAuthUrl} from "../salesforceClient";
import {GRANT_TYPE} from "../types";
import {GAnalyticsEvents} from "../../events-trackers/gAnalytics-events";

export function SalesforceLoginButton(props: {
    grantType: GRANT_TYPE,
    disabled: boolean,
}) {
    const {grantType, disabled} = props

    return (
        <Button
            variant="contained"
            color="primary"
            disabled={disabled}
            href={connectedAppOAuthUrl(grantType)}
            onClick={() => { GAnalyticsEvents.eventSfLoginOutbound() }}
        >
            Login to Salesforce
        </Button>
    )
}