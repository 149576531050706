import {GlobalStates} from "../../../../config/global";
import {TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {verifyGistId, verifyGistToken} from "../../../../gist/gistClient";
import {pushErrorToNotification} from "../../../../generator/backendClient";

interface GistHelper {
    text: string | null,
    color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
}

const gistTokenHelperTexts: { [key: string]: GistHelper } = {
    VERIFYING: {text: "Verifying...", color: "primary"},
    EMPTY: {text: "If not provided, code is generated to our account", color: "primary"},
    LENGTH: {text: "ID length has to have 40 characters", color: "warning"},
    GHP: {text: "Token have to start with ghp_", color: "warning"},
    SUCCESS: {text: "Token is valid", color: "success"},
    INVALID: {text: "Token is invalid", color: "error"},
}

export function GistTextField(props: {
    gistTokenInputState: [(string | null), (newVal: (string | null)) => void],
    notificationState: GlobalStates["notificationState"],
    isDemo: boolean,
}) {
    const {notificationState, gistTokenInputState} = props
    const [gistTokenInput, setGistTokenInput] = gistTokenInputState

    // Gist Token
    const [gistTokenInputHelperText, setGistTokenInputHelperText] = useState<GistHelper["text"]>(null)
    const [gistTokenInputHelperColor, setGistTokenInputHelperColor] = useState<GistHelper["color"]>("primary")
    const [validGistTokens, setValidGistTokens] = useState<string[]>([])
    const [invalidGistTokens, setInvalidGistTokens] = useState<string[]>([])

    const verifyToken = async (token: string) => {
        try {
            const isValid = await verifyGistToken(token, notificationState)
            if (isValid) {
                setValidGistTokens(prev => [...prev, token])
                setGistTokenInputHelperText(() => gistTokenHelperTexts.SUCCESS.text)
                setGistTokenInputHelperColor(() => gistTokenHelperTexts.SUCCESS.color)
                setGistTokenInput(token)
            } else {
                setInvalidGistTokens(prev => [...prev, token])
                setGistTokenInputHelperText(() => gistTokenHelperTexts.INVALID.text)
                setGistTokenInputHelperColor(() => gistTokenHelperTexts.INVALID.color)
            }
            // GAnalyticsEvents.eventInputGistTokenSuccessful()
            return
        } catch (e) {
            console.error(e)
            pushErrorToNotification(e, notificationState)
            setGistTokenInputHelperText(() => gistTokenHelperTexts.INVALID.text)
            setGistTokenInputHelperColor(() => gistTokenHelperTexts.INVALID.color)
            return
        }
    }

    const onGistTokenChange = (input: string) => {

        if (validGistTokens.includes(input)) {
            setGistTokenInputHelperText(() => gistTokenHelperTexts.SUCCESS.text)
            setGistTokenInputHelperColor(() => gistTokenHelperTexts.SUCCESS.color)
            return
        }
        if (invalidGistTokens.includes(input)) {
            setGistTokenInputHelperText(() => gistTokenHelperTexts.INVALID.text)
            setGistTokenInputHelperColor(() => gistTokenHelperTexts.INVALID.color)
            return
        }
        if (input === "") {
            setGistTokenInputHelperText(() => gistTokenHelperTexts.EMPTY.text)
            setGistTokenInputHelperColor(() => gistTokenHelperTexts.EMPTY.color)
            setGistTokenInput(null)
            return
        }
        if (!input.startsWith("ghp_")) {
            setGistTokenInputHelperText(() => gistTokenHelperTexts.GHP.text)
            setGistTokenInputHelperColor(() => gistTokenHelperTexts.GHP.color)
            return
        }
        if (input.length !== 40) {
            setGistTokenInputHelperText(() => gistTokenHelperTexts.LENGTH.text)
            setGistTokenInputHelperColor(() => gistTokenHelperTexts.LENGTH.color)
            return
        }

        verifyToken(input).then().catch()
    }

    useEffect(() => {
        onGistTokenChange(gistTokenInput || "")
    }, [])

    return (
        <>
            <TextField
                fullWidth={true}
                spellCheck={false}
                id="gist-token"
                label="Gist token"
                variant="standard"
                defaultValue={gistTokenInput}
                color={gistTokenInputHelperColor}
                helperText={gistTokenInputHelperText}
                onChange={event => onGistTokenChange(event.target.value)}
            />
        </>
    )
}