import {GlobalStates} from "../../../config/global";
import {ObjectSelection} from "../../types";
import {GenerateCodeButton} from "./GenerateCodeButton";
import {Grid, Typography} from "@mui/material";
import React, {useMemo, useState} from "react";
import {GistSettingsModal} from "./settings/GistSettingsModal";
import {GeneratorRequest} from "../../../generator/requests";
import {SettingsModal} from "./settings/SettingsModal";

export type GeneratorOptions = Omit<GeneratorRequest["options"]["salesforceOptions"], "credentials">

/**
 * Contains generate settings and generate button
 * @param props
 * @constructor
 */
export function GenerateCodeWrapper(props: {
    salesforceAuthState: GlobalStates["salesforceAuthState"],
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
    gistObjectsState: GlobalStates["gistState"],
    gistUseCasesState: GlobalStates["gistState"],
    checkedObjects: ObjectSelection[],
    salesforceInstanceUrl: string | undefined,
    isDemo: boolean,
}) {
    const {
        notificationState,
        signInState,
        gistObjectsState,
        gistUseCasesState,
        checkedObjects,
        salesforceInstanceUrl,
        salesforceAuthState,
        isDemo
    } = props
    const [salesforceAuth] = salesforceAuthState

    const gistTokenInputState = useState<string | null>(null)
    const [gistTokenInput] = gistTokenInputState

    const generatorOptionsState = useState<GeneratorOptions>({
        language: "KOTLIN",
    })
    const [generatorOptions] = generatorOptionsState

    const options: GeneratorRequest["options"] = useMemo(() => {
        return {
            gistOptions: {
                token: gistTokenInput,
            },
            salesforceOptions: {
                ...generatorOptions,
                credentials: salesforceAuth!,
            }
        }
    }, [generatorOptionsState, gistTokenInput, salesforceInstanceUrl, salesforceAuth])

    const gistInfo = useMemo(() => {
        if (gistTokenInput != null) return "Code will be generated to provided gist token under new ID"
        return "Code will be generated to our gist token"
    }, [gistTokenInput])

    const generateActionsDisabled = checkedObjects.length === 0
    return (
        <>
            {/*Generate*/}
            <Grid paddingTop={"15px"}>
                <Typography variant={'subtitle2'}>{gistInfo}</Typography>
                <GistSettingsModal
                    gistTokenInputState={gistTokenInputState}
                    notificationState={notificationState}
                    generateActionsDisabled={generateActionsDisabled}
                    isDemo={isDemo}
                />
                <SettingsModal
                    generatorOptionsState={generatorOptionsState}
                    notificationState={notificationState}
                    generateActionsDisabled={generateActionsDisabled}
                    isDemo={isDemo}
                />
                <GenerateCodeButton
                    notificationState={notificationState}
                    signInState={signInState}
                    gistObjectsState={gistObjectsState}
                    gistUseCasesState={gistUseCasesState}
                    checkedObjects={checkedObjects}
                    generatorOptions={options}
                    generateActionsDisabled={generateActionsDisabled}
                    isDemo={isDemo}
                />
            </Grid>
        </>
    )
}