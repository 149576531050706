import React, {useRef, useState} from "react";
import {ButtonGroup, Typography} from "@mui/material";
import {SalesforceObjectType} from "../types";
import {LoadingButton} from "@mui/lab";
import {GlobalStates} from "../../config/global";
import {mockedObjects} from "../../mocks/mockProvider";
import {GAnalyticsEvents} from "../../events-trackers/gAnalytics-events";
import {queryObjects} from "../backendClient";

export function LoadObjectsButton(props: {
    salesforceAuthState: GlobalStates["salesforceAuthState"],
    notificationState: GlobalStates["notificationState"],
    objectsState: GlobalStates["objectsState"],
    signInState: GlobalStates["signInState"],
    isDemo: boolean,
}) {
    const anchorRef = useRef<HTMLDivElement>(null)
    const {isDemo, notificationState, objectsState, salesforceAuthState, signInState} = props

    const [loadingObjects, setLoadingObjects] = useState(false)
    const [,setAllObjects] = objectsState
    const [salesforceAuth] = salesforceAuthState

    const handleClick = async (type: SalesforceObjectType) => {
        setLoadingObjects(true)

        const loadedObjects = (isDemo)
            ? await mockedObjects(type)
            : await queryObjects(type, salesforceAuth!, signInState, notificationState)
        GAnalyticsEvents.eventLoadObjects(`${(isDemo) ? "mocked" : "real"} ${type}`)

        setLoadingObjects(false)
        setAllObjects(
            (loadedObjects).map(value => {
                return {
                    name: value,
                    checked: false,
                    type: (value.endsWith("__c") ? "custom" : "standard"),
                    fields: [],
                }
            })
        )
    }

    const buttonDisabled = (!isDemo && salesforceAuth == null)

    return (
        <>
            <Typography>Which objects you want to load?</Typography>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                <LoadingButton
                    variant="contained"
                    disabled={buttonDisabled}
                    onClick={() => handleClick("standard")}
                    loading={loadingObjects}
                >
                    Standard
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    disabled={buttonDisabled}
                    onClick={() => handleClick("custom")}
                    loading={loadingObjects}
                >
                    Custom
                </LoadingButton>
                <LoadingButton
                    variant="contained"
                    disabled={buttonDisabled}
                    onClick={() => handleClick("all")}
                    loading={loadingObjects}
                >
                    All
                </LoadingButton>
            </ButtonGroup>
        </>
    )
}