import React, {useEffect} from "react";

import {HomeSection} from "./HomeSection";

export function Landing() {
    useEffect(() => {
        document.title = "Convert Salesforce Objects to Kotlin/Java Code in Minutes with Salesforce CodeGen"
    }, [])

    return (
        <>
            <HomeSection/>
        </>
    )
}