import {GlobalStates} from "../config/global";
import {RecordObjectField, SalesforceInstances, SalesforceLoginResponse, SalesforceObjectType} from "./types";
import {validateAuthToken} from "../auth/refreshService";
import axios from "axios";
import {ServerEnv} from "../config/envVariables";
import {pushErrorToNotification} from "../generator/backendClient";

// LOGIN
/**
 * Acquired token is used for login. Because we do not want to expose our client ID/Secret to FE, we do this via backend.
 * @param code callback from SF auth response used for login
 * @param signInState
 * @param notificationState
 */
export const loginWithCode = async (
    code: string,
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<SalesforceLoginResponse | undefined> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/salesforce/login/code",
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
        },
        data: {code},
    })
        .then(value => {
            let result = value.data as SalesforceLoginResponse
            return result
        })
        .catch(reason => pushErrorToNotification(reason, notificationState))
}

// QUERY
export const queryObjects = async (
    type: SalesforceObjectType,
    sfLogin: SalesforceLoginResponse,
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<string[]> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/salesforce/query/objects",
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
        },
        data: {
            objectType: type.toUpperCase(),
            accessToken: sfLogin.accessToken,
            instanceUrl: sfLogin.instanceUrl,
        },
    })
        .then(value => {
            return value.data as string[]
        })
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return []
        })
}

export const queryFields = async (
    objects: string[],
    sfLogin: SalesforceLoginResponse,
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<RecordObjectField[]> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/salesforce/query/fields",
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
        },
        data: {
            objects,
            accessToken: sfLogin.accessToken,
            instanceUrl: sfLogin.instanceUrl,
        },
    })
        .then(value => {
            return value.data as RecordObjectField[]
        })
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return []
        })
}

export const getSfInstances = async (
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<SalesforceInstances[]> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/salesforce/instances",
        method: "GET",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
        },
    })
        .then(value => {
            return value.data as SalesforceInstances[]
        })
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return []
        })
}