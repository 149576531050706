export function isInitialized(...o: Array<any | undefined | null>): boolean {
    return o.every(value => !(!value))
}

export function assertAllValuesAreDefined(o: any) {
    let undefinedProps: Array<string> = []

    Object.keys(o).forEach(value => {
        if (o[value] === undefined) undefinedProps.push(value)
    })

    if (undefinedProps.length > 0) throw new Error(`Properties [${undefinedProps.join(", ")}] are undefined`)
}