import {ThemeOptions} from "@mui/material";

const lightBlur = {
    backgroundColor: "rgba(255, 255, 255, 0.75)",
    backdropFilter: "blur(20px)",
}
const darkBlur = {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    backdropFilter: "blur(20px)",
}
const unsetColorsInnerPaper = {
    "& .MuiPaper-root": {
        color: "unset",
        background: "unset",
    },
    "&.MuiPaper-root.MuiSnackbarContent-root": {
        color: "unset",
    },
}

const customLightTheme: ThemeOptions = {
    components: {
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: lightBlur,
                }
            }
        },
        MuiSnackbarContent: {
            defaultProps: {
                sx: {
                    ...lightBlur,
                    ...unsetColorsInnerPaper,
                },
            }
        },
        // MuiSnackbar: {
        //     defaultProps: {
        //         sx: {
        //             ...lightBlur,
                    // ...unsetColorsInnerPaper,
        //         },
        //     }
        // },
    }
}
const customDarkTheme: ThemeOptions = {
    components: {
        MuiDrawer: {
            defaultProps: {
                PaperProps: {
                    sx: darkBlur,
                }
            }
        },
        MuiSnackbarContent: {
            defaultProps: {
                sx: {
                    ...darkBlur,
                    // ...unsetColorsInnerPaper,
                },
            }
        },
        // MuiSnackbar: {
        //     defaultProps: {
        //         sx: {
        //             ...darkBlur,
        //             // ...unsetColorsInnerPaper,
        //         },
        //     }
        // },
    }
}

export const CustomTheme = {
    customLightTheme,
    customDarkTheme,
}