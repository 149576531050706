import {Box, Button, Modal, Tooltip} from "@mui/material";
import React, {useState} from "react";
import {GlobalStates} from "../../../../config/global";
import {Settings} from "@mui/icons-material";
import {GistTextField} from "./GistTextField";
import {ModalUtils} from "./ModalUtils";

export function GistSettingsModal(props: {
    gistTokenInputState: [(string | null), (newVal: (string | null)) => void],
    notificationState: GlobalStates["notificationState"],
    generateActionsDisabled: boolean,
    isDemo: boolean,
}) {
    const {
        gistTokenInputState,
        notificationState,
        generateActionsDisabled,
        isDemo
    } = props
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const buttonDisabled = () => generateActionsDisabled || isDemo
    const unavailableTooltipMsg = "Settings are unavailable in Demo"

    return (
        <>
            <Tooltip
                title={buttonDisabled() ? unavailableTooltipMsg : ""}
                arrow>
                <div style={{ display: 'inline-block' }}>
                    <Button
                        disabled={buttonDisabled()}
                        onClick={handleOpen}
                        startIcon={<Settings/>}
                    >Gist</Button>
                </div>
            </Tooltip>
            <Modal
                sx={ModalUtils.modalStyle}
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={ModalUtils.boxStyle}>
                    {/*Gist option*/}
                    <GistTextField
                        gistTokenInputState={gistTokenInputState}
                        notificationState={notificationState}
                        isDemo={isDemo}
                    />
                </Box>
            </Modal>
        </>
    )
}