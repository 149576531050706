import {GoogleAds} from "../config/envVariables";

declare global {
    interface Window {
        dataLayer: any[];
        gtag: (..._args: any[]) => void;// this is already set by react-ga4
    }
}

const conversionId = GoogleAds.conversionId
const subscribeLabel = GoogleAds.subscribeLabel

const conversionSubscribe = (value: number) => {
    window.gtag('event', 'conversion', {
        'send_to': `${conversionId}/${subscribeLabel}`,
        'value': value,
        'currency': 'USD',
    })
}

export const GAdsService = {
    subscribed: conversionSubscribe,
}