import {ServerEnv} from "../config/envVariables";
import Cookies from "js-cookie";
import {CookieNames} from "../config/global";
import {SalesforceLoginResponse} from "../salesforce/types";

// Cookie consent
const isConsentAccepted = (): boolean => Cookies.get(CookieNames.acceptedCookieConsent) === "true"

const acceptCookieConsent = () => Cookies.set(CookieNames.acceptedCookieConsent, "true")

// JWT tokens
const getRefreshExpiresAt = () => Cookies.get(CookieNames.refreshTokenExpiresAt)

const removeRefreshCookies = () => {
    Cookies.remove(CookieNames.authTokenExpiresAt)
    Cookies.remove(CookieNames.refreshTokenExpiresAt)
}

// Salesforce Login
const storeSalesforceLogin = (login: SalesforceLoginResponse) => {
    const expireAt = new Date()
    expireAt.setHours(expireAt.getHours() + 2)//SF token expiration

    Cookies.set(CookieNames.salesforceLogin, JSON.stringify(login), {
        "httpOnly": false,
        secure: false,
        sameSite: "Strict",
        expires: expireAt,
        domain: ServerEnv.domain,
    })
}

const getSalesforceLogin = () => {
    const serializedSfLogin = Cookies.get(CookieNames.salesforceLogin);
    if (serializedSfLogin != null) return JSON.parse(serializedSfLogin) as SalesforceLoginResponse
}

const removeSalesforceLogin = () => Cookies.remove(CookieNames.salesforceLogin)

export const CookieService = {
    // cookie consent
    isConsentAccepted,
    acceptCookieConsent,
    // jwt refresh token
    getRefreshExpiresAt,
    removeRefreshCookies,
    // salesforce
    storeSalesforceLogin,
    getSalesforceLogin,
    removeSalesforceLogin,
}