import {Button, Grid, Typography, useTheme} from "@mui/material";
import {JsonSnippet} from "./JsonSnippet";
import {KotlinSnippet} from "./KotlinSnippet";
import React from "react";
import {OverlappingCards} from "./OverlappingCards";
import {PlayArrowOutlined} from "@mui/icons-material";
import {FancyArrow} from "./FancyArrow";
import {YoutubeModal} from "./YoutubeModal";

export function HomeSection() {
    const theme = useTheme()
    const openVideoState = React.useState(false)
    const [, setOpenVideo] = openVideoState

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6} style={{paddingTop: "65px"}}>
                    <Typography variant="h3" gutterBottom>
                        Convert <strong>APEX</strong> objects
                        into <strong>Kotlin</strong> or <strong>Java</strong>
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        just click generate and copy the result code
                    </Typography>
                    <div style={{marginTop: theme.spacing(2)}}>
                        <Button
                            variant={undefined}
                            startIcon={<i className="ion-ios-play-outline"></i>}
                            onClick={() => setOpenVideo(true)}
                        >
                            <span style={{
                                borderRadius: "50%",
                                transition: "all 0.3s ease-out",
                                width: 40,
                                height: 40,
                                textAlign: "center",
                                verticalAlign: "middle",
                                marginRight: 8,
                                background: theme.palette.primary.main,
                            }}><i style={{
                                color: "#fff",
                                fontSize: 26,
                            }}><PlayArrowOutlined/></i></span>
                            Watch Video
                            <span style={{
                                overflow: "hidden",
                                pointerEvents: "none",
                                position: "absolute",
                                zIndex: 0,
                                inset: 0,
                                borderRadius: "inherit",
                            }}/>
                        </Button>
                        <Button
                            variant="contained"
                            color="warning"
                            size="large"
                            style={{marginLeft: theme.spacing(2), borderRadius: 36}}
                            href="/generator"
                        >Try DEMO</Button>
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <JsonSnippet/>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <KotlinSnippet/>
                </Grid>
                <Grid item xs={3}>
                    <FancyArrow styles={{transform: "rotate(149deg)"}}/>
                    <Typography variant="h4"  style={{
                        transform: "rotate(318deg)",
                        position: "relative",
                        bottom: 91,
                        left: 75,
                    }}>Convert</Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={5}>
                    <FancyArrow styles={{transform: "rotate(198deg) scaleX(-1)"}}/>
                    <Typography variant="h4"  style={{
                        transform: "rotate(29deg)",
                        position: "relative",
                        bottom: 21,
                        left: 194,
                    }}>Query</Typography>
                </Grid>
                <Grid item xs={7}>
                    <OverlappingCards/>
                </Grid>
            </Grid>
            <YoutubeModal openState={openVideoState}/>
        </>
    )
}