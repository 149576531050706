import ReactGA from "react-ga4";
import {UaEventOptions} from "react-ga4/types/ga4";

// Recommended events   https://support.google.com/analytics/answer/9267735
// const salesforceLogin = () => ReactGA.event("login", { method: "Salesforce" })

// Custom events        https://support.google.com/analytics/answer/1033068
const genericGmailEvent = (action: UaEventOptions["action"]) => ReactGA.event({
    category: "Gmail",
    action,
})
const gmailLoginSuccessful = () => genericGmailEvent("Login successful")
const gmailLoginFailed = () => genericGmailEvent("Login failed")
const gmailLogout = () => genericGmailEvent("Logout")

const genericSalesforceEvent = (action: UaEventOptions["action"], label: UaEventOptions["label"] = undefined) =>
    ReactGA.event({
        category: "Salesforce",
        action,
        label,
    })
const salesforceLogout = () => genericSalesforceEvent("Logout")
const salesforceLoginOutbound = () => genericSalesforceEvent("Login outbound")
const salesforceLoginSuccessful = () => genericSalesforceEvent("Login successful")
const salesforceInstanceOutbound = () => genericSalesforceEvent("Instance outbound")

const genericGeneratorEvent = (action: UaEventOptions["action"], label: UaEventOptions["label"] = undefined) =>
    ReactGA.event({
        category: "Generate",
        action,
        label,
    })
const loadFields = (label: string) => genericGeneratorEvent("Load Fields", label)
const loadObjects = (label: string) => genericGeneratorEvent("Load Objects", label)
const generateCode = (label: string) => genericGeneratorEvent("Generate code", label)

const themeMode = (label: string) => ReactGA.event({
    category: "Theme",
    action: "Toggle",
    label,
})

const openInGithub = () => ReactGA.event({
    category: "Github",
    action: "Open in Github",
    label: "view generated code",
})


export const GAnalyticsEvents = {
    eventThemeToggle: (label: string) => themeMode(label),

    eventGmailLoginSuccessful: gmailLoginSuccessful,
    eventGmailLoginFailed: gmailLoginFailed,
    eventGmailLogout: gmailLogout,

    eventSfLoginOutbound: salesforceLoginOutbound,
    eventSfLoginSuccessful: salesforceLoginSuccessful,
    eventSfLogout: salesforceLogout,
    eventSfInstanceOutbound: salesforceInstanceOutbound,

    eventLoadObjects: (label: string) => loadObjects(label),
    eventLoadFields: (label: string) => loadFields(label),
    eventGenerateCode: (label: string) => generateCode(label),

    eventGithubView: openInGithub,
}