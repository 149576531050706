import {Button} from "@mui/material";
import React from "react";
import {GlobalStates} from "../../config/global";
import {GAnalyticsEvents} from "../../events-trackers/gAnalytics-events";
import {CookieService} from "../../cookies/cookiesService";

export function SalesforceLogoutButton(props: {
    salesforceAuthState: GlobalStates["salesforceAuthState"],
}) {
    const [, setSalesforceAuth] = props.salesforceAuthState

    return (
        <Button variant="contained" color="primary" onClick={() => {
            GAnalyticsEvents.eventSfLogout()
            setSalesforceAuth(undefined)
            CookieService.removeSalesforceLogin()
        }}>
            Logout from Salesforce
        </Button>
    )
}