import {GAnalyticsEvents} from "../../events-trackers/gAnalytics-events";
import {IconButton, SvgIcon, Tooltip, useTheme} from "@mui/material";
import React from "react";
import {ReactComponent as GithubIconLight} from '../../assets/github-mark-light-blue.svg'
import {ReactComponent as GithubIconDark} from '../../assets/github-mark-dark-blue.svg'
import {Gist} from "../../generator/responses";

export function OpenInGitHubIconButton(props: {
    gist: Gist,
}) {
    const {gist} = props

    const theme = useTheme()
    const isDarkMode = theme.palette.mode === 'dark'
    const githubIcon = isDarkMode ? GithubIconDark : GithubIconLight

    const label = "Open in GitHub"

    return (
        <IconButton
            color="info"
            href={gist.htmlUrl}
            target="_blank"
            rel="noreferrer"
            aria-label={label}
            onClick={() => GAnalyticsEvents.eventGithubView()}
        >
            <Tooltip
                placement="left"
                title={label}
                arrow
            >
                <SvgIcon
                    component={githubIcon}
                    viewBox="0 0 98 96"
                />
            </Tooltip>
        </IconButton>
    )
}