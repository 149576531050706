import "./styles/synthwave-84.css"
import "./styles/codemirror.min.css"
import "./styles/jsx-4023755556.css"
import "./styles/jsx-1170653115.css"
import "./styles/jsx-1831890883.css"
import "./styles/jsx-43165554.css"

const carbonCopy = <div id="export-container" className="export-container">
    <div className="jsx-1831890883 container" style={{minWidth: 648}}>
        <div className="jsx-4023755556 window-controls">
            <svg xmlns="http://www.w3.org/2000/svg" width="54" height="14" viewBox="0 0 54 14">
                <g fill="none" fillRule="evenodd" transform="translate(1 1)">
                    <circle cx="6" cy="6" r="6" fill="#FF5F56" stroke="#E0443E" strokeWidth=".5"></circle>
                    <circle cx="26" cy="6" r="6" fill="#FFBD2E" stroke="#DEA123" strokeWidth=".5"></circle>
                    <circle cx="46" cy="6" r="6" fill="#27C93F" stroke="#1AAB29" strokeWidth=".5"></circle>
                </g>
            </svg>
            <div className="jsx-1170653115"><input aria-label="Image title" type="text" spellCheck="false"
                                                   className="jsx-1170653115" value="" readOnly/></div>
        </div>
        <div className="react-codemirror2 CodeMirror__container window-theme__none">
            <div className="CodeMirror CodeMirror-wrap cm-s-synthwave-84" translate="no">
                <div
                    style={{
                        overflow: "hidden",
                        position: "relative",
                        width: 3,
                        height: 0,
                        top: 182.266,
                        left: 201.484,
                    }}>
                    <textarea autoCorrect="off" autoCapitalize="off" spellCheck="false" aria-label="Code editor"
                              tabIndex={0}
                              style={{
                                  position: "absolute",
                                  bottom: "-1em",
                                  padding: 0,
                                  width: 1000,
                                  height: "1em",
                                  minHeight: "1em",
                                  outline: "none",
                              }}></textarea>
                </div>
                <div className="CodeMirror-scrollbar-filler" cm-not-content="true"></div>
                <div className="CodeMirror-gutter-filler" cm-not-content="true"></div>
                <div className="CodeMirror-scroll" tabIndex={-1} draggable="false">
                    <div className="CodeMirror-sizer"
                         style={{
                             marginLeft: 0,
                             marginBottom: 0,
                             borderRightWidth: 50,
                             paddingRight: 0,
                             paddingBottom: 0,
                         }}>
                        <div style={{position: "relative", top: 0,}}>
                            <div className="CodeMirror-lines" role="presentation">
                                <div role="presentation" style={{position: "relative", outline: "none",}}>
                                    <div className="CodeMirror-measure">
                                        <pre className="CodeMirror-line-like">x</pre>
                                    </div>
                                    <div className="CodeMirror-measure"></div>
                                    <div style={{position: "relative", zIndex: 1,}}></div>
                                    <div className="CodeMirror-cursors">
                                        <div className="CodeMirror-cursor"
                                             style={{
                                                 left: "299.078px",
                                                 top: "18.6094px",
                                                 height: "18.6094px",
                                             }}>&nbsp;</div>
                                    </div>
                                    <div className="CodeMirror-code" role="presentation"
                                         style={{textRendering: "auto",}}>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            className="cm-keyword">fun</span> <span
                                            className="cm-def">main</span>() &#123;</span></pre>
                                        <pre className="CodeMirror-line" role="presentation"><span role="presentation"
                                                                                                   style={{paddingRight: "0.1px"}}> &nbsp; &nbsp;
                                            <span className="cm-keyword">val</span> <span
                                                className="cm-def">client</span> <span className="cm-operator">=</span> <span
                                                className="cm-variable">SalesforceClient</span>()</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            cm-text="">​</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}> &nbsp; &nbsp;
                                            <span className="cm-keyword">val</span> <span
                                                className="cm-def">accounts</span> <span
                                                className="cm-operator">=</span> <span
                                                className="cm-variable">client</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            className="cm-tab" role="presentation"
                                            cm-text="	">    </span> &nbsp;.<span
                                            className="cm-variable cm-highlight">getWhere</span>(<span
                                            className="cm-variable">Account</span>::<span
                                            className="cm-keyword">class</span>.<span
                                            className="cm-variable">java</span>, <span
                                            className="cm-keyword">where</span> <span
                                            className="cm-operator">=</span> <span className="cm-string">"name like 'United Oil%'"</span>)</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}> &nbsp;
                                            <span className="cm-tab" role="presentation"
                                                  cm-text="	">    </span>.<span
                                                className="cm-variable">records</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            cm-text="">​</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}> &nbsp; &nbsp;
                                            <span className="cm-variable">println</span>(<span
                                                className="cm-string">"Output:"</span>)</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}> &nbsp; &nbsp;
                                            <span className="cm-variable">accounts</span>.<span
                                                className="cm-variable">forEach</span> <span
                                                className="cm-variable">println</span>(<span
                                                className="cm-variable">it</span>.<span className="cm-variable">name</span>)</span></pre>
                                                <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                style={{paddingRight: "0.1px"}}>&#125;</span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            className="cm-comment">// Output:</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            className="cm-comment">// United Oil &amp; Gas, UK</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            className="cm-comment">// United Oil &amp; Gas, Singapore</span></span></pre>
                                        <pre className=" CodeMirror-line " role="presentation"><span role="presentation"
                                                                                                     style={{paddingRight: "0.1px"}}><span
                                            className="cm-comment">// United Oil &amp; Gas Corp.</span></span></pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            height: 50,
                            width: 1,
                            borderBottom: "0px solid transparent",
                            top: 269,
                        }}></div>
                    <div className="CodeMirror-gutters" style={{display: "none", height: 319,}}></div>
                </div>
            </div>
        </div>
    </div>
</div>

export function QueryWhereSnippet() {
    return (carbonCopy)
}