import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Grid} from "@mui/material";
import {GlobalStates} from "../../config/global";
import {ObjectSelection} from "../types";
import {LoadObjectsButton} from "./LoadObjectsButton";
import {LoadedObjectsList} from "./LoadedObjectsList";
import {GistCategoryAccordions} from "./GistCategoryAccordions";
import {GenerateCodeWrapper} from "./generate/GenerateCodeWrapper";
import {SalesforceRadioGroup} from "./SalesforceRadioGroup";
import {MavenImportsSection} from "./MavenImportsSection";

export const DEMO_INSTANCE = "demo"

export function Generator(props: {
    salesforceAuthState: GlobalStates["salesforceAuthState"],
    notificationState: GlobalStates["notificationState"],
    objectsState: GlobalStates["objectsState"],
    signInState: GlobalStates["signInState"],
    gistObjectsState: GlobalStates["gistState"],
    gistUseCasesState: GlobalStates["gistState"],
}) {
    const {salesforceAuthState, notificationState, objectsState, signInState, gistObjectsState, gistUseCasesState: gistUseCasesState} = props
    const [salesforceAuth] = salesforceAuthState

    const [allObjects, setAllObjects] = objectsState
    const checkedObjects: ObjectSelection[] = allObjects.filter(value => value.checked)
    const checkedObjectsCount: number = checkedObjects.length || 0

    const [gistObjects] = gistObjectsState
    const [gistUseCases] = gistUseCasesState

    const [signIn] = signInState

    const selectedSfAccountState = useState(DEMO_INSTANCE)
    const [selectedSfAccount] = selectedSfAccountState

    const isDemo = useMemo(() =>
            (selectedSfAccount === DEMO_INSTANCE) || (signIn == null) || (salesforceAuth == null),
        [selectedSfAccount, signIn, salesforceAuth])
    useEffect(() => setAllObjects([]), [isDemo])

    useEffect(() => {document.title = "Generator"}, [])

    const handleObjectClick = useCallback((change: ObjectSelection) => {
        let index = allObjects.findIndex(value => value === change)
        let copy = [...allObjects]
        copy.splice(index, 1, {
            ...change,
            checked: !change.checked,
        })
        setAllObjects(copy)
    }, [allObjects, setAllObjects])

    const objectsToList = useMemo(() => (header: string, objects: ObjectSelection[]) => {
        return <LoadedObjectsList header={header} objects={objects} handleObjectClick={handleObjectClick}/>
    }, [handleObjectClick])

    let StandardObjects = useMemo(() => {
        return objectsToList("Standard Objects", allObjects.filter(value => value.type === "standard"))
    }, [allObjects, objectsToList])
    let CustomObjects = useMemo(() => {
        return objectsToList("Custom Objects", allObjects.filter(value => value.type === "custom"))
    }, [allObjects, objectsToList])

    return (
        <>
            <Grid item xs={12} paddingBottom={"30px"}>
                <SalesforceRadioGroup
                    signInState={signInState}
                    notificationState={notificationState}
                    salesforceAuthState={salesforceAuthState}
                    selectedSfAccountState={selectedSfAccountState}
                />
            </Grid>
            <Grid item xs={12} paddingBottom={"30px"}>
                <LoadObjectsButton
                    salesforceAuthState={salesforceAuthState}
                    notificationState={notificationState}
                    objectsState={objectsState}
                    signInState={signInState}
                    isDemo={isDemo}
                />
            </Grid>

            {// Show Objects section
                (allObjects.length > 0) && <>
                    <Grid container spacing={4}>
                        <Grid item xs={6} paddingBottom={"30px"}>
                            {StandardObjects}
                        </Grid>
                        <Grid item xs={6} paddingBottom={"30px"}>
                            {CustomObjects}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} paddingBottom={"30px"}>
                        {checkedObjectsCount === 0
                            ? <p>Select objects that you need to implement ☝️</p>
                            : <p>Selected <strong>{checkedObjectsCount}</strong> objects.</p>}
                    </Grid>

                    <Grid item xs={3} paddingBottom={"50px"}>
                        <GenerateCodeWrapper
                            salesforceAuthState={salesforceAuthState}
                            notificationState={notificationState}
                            signInState={signInState}
                            gistObjectsState={gistObjectsState}
                            gistUseCasesState={gistUseCasesState}
                            checkedObjects={checkedObjects}
                            salesforceInstanceUrl={salesforceAuth?.instanceUrl}
                            isDemo={isDemo}
                        />
                    </Grid>
                    <MavenImportsSection
                        gist={gistObjects}
                        notificationState={notificationState}/>
                    <GistCategoryAccordions
                        gist={gistObjects}
                        categoryName="OBJECTS"
                        notificationState={notificationState}
                    />
                    <GistCategoryAccordions
                        gist={gistUseCases}
                        categoryName="USE-CASES"
                        notificationState={notificationState}
                    />
                </>
            }
        </>
    )
}