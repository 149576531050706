import React, {useEffect, useMemo} from "react";
import {Grid, Link, Typography} from "@mui/material";
import {SalesforceLoginButton} from "./SalesforceLoginButton";
import {SalesforceLogoutButton} from "./SalesforceLogoutButton";
import {useSearchParams} from "react-router-dom";
import {GlobalStates} from "../../config/global";
import {GRANT_TYPE, SalesforceLoginResponse} from "../types";
import {isInitialized} from "../../config/assertions";
import {pushErrorToNotification} from "../../generator/backendClient";
import {GAnalyticsEvents} from "../../events-trackers/gAnalytics-events";
import {CookieService} from "../../cookies/cookiesService";
import {loginWithCode} from "../backendClient";

export function SalesforceLoginStatus(props: {
    salesforceAuthState: GlobalStates["salesforceAuthState"],
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
}) {
    const {signInState, salesforceAuthState, notificationState} = props
    const [salesforceAuth, setSalesforceAuth] = salesforceAuthState

    const [params, setParams] = useSearchParams()
    const sfCallbackCode = params.get("code")

    const loginMessage = useMemo(() => {
            const message = salesforceAuth == null
                ? <Typography>{`Login with salesforce account that you want to use in Generator`}</Typography>
                : <Typography>{`You are logged with ${salesforceAuth?.instanceUrl}`}</Typography>
            return <Grid item xs={3} paddingBottom={"30px"}>
                {message}
            </Grid>
        }
    , [salesforceAuth])

    // handle redirect from Salesforce OAuth
    useEffect(() => {
        if (!isInitialized(salesforceAuth) && isInitialized(sfCallbackCode)) {
            try {
                loginWithCode(sfCallbackCode!, signInState, notificationState)
                    .then(value => {
                        if (value != null) {
                            CookieService.storeSalesforceLogin(value)
                            setSalesforceAuth(value)
                        }
                    })
                    .catch(reason => {
                        setSalesforceAuth(undefined)
                        pushErrorToNotification(reason, notificationState)
                    })
                params.delete("code")
                setParams(params)
                GAnalyticsEvents.eventSfLoginSuccessful()
            } catch (e) {
                console.error(e)
            }
        }
    }, [params, sfCallbackCode, salesforceAuth, setSalesforceAuth, setParams])

    useEffect(() => {
        document.title = "Salesforce manager"
    }, [])

    const emptyResponse: SalesforceLoginResponse = {
        accessToken: "null",
        instanceUrl: "null",
    }
    const authKeys = () => Object.keys(salesforceAuth || emptyResponse) as Array<keyof SalesforceLoginResponse>

    const LoginOrLogout = <Grid item xs={3} paddingBottom={"30px"}>
        {salesforceAuth
            ? <SalesforceLogoutButton
                salesforceAuthState={salesforceAuthState}
            />
            : <SalesforceLoginButton grantType={GRANT_TYPE.CODE} disabled={signInState == null}/>
        }
    </Grid>
    const ChangeSfInstanceInfo = (salesforceAuth)
        ? <p>To use different salesforce account, first <Link
            href={salesforceAuth.instanceUrl}
            target="_blank"
            rel="noreferrer"
            onClick={() => GAnalyticsEvents.eventSfInstanceOutbound()}>
            logout
        </Link> from currently logged-in account.</p>
        : <p>If you experience auto-login to different salesforce account, you can <Link
            href={"https://login.salesforce.com/"}
            target="_blank"
            rel="noreferrer"
            onClick={() => GAnalyticsEvents.eventSfInstanceOutbound()}>
            logout here.
        </Link></p>

    return (
        <>
            {loginMessage}
            {LoginOrLogout}
            {ChangeSfInstanceInfo}
        </>
    )
}