import {ListItemIcon, ListItemText, useTheme} from "@mui/material";
import {Brightness4, Brightness7} from "@mui/icons-material";
import React from "react";

export interface ThemeToggleProps {
    ColorModeContext: React.Context<{toggleColorMode: () => void}>
}

export function ThemeToggle(props: ThemeToggleProps & {open: boolean}) {
    const {open} = props

    const theme = useTheme()

    return (
        <>
            <ListItemIcon sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
            }}>
                {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
            </ListItemIcon>
            <ListItemText primary={theme.palette.mode + ' mode'} sx={{ opacity: open ? 1 : 0 }} />
        </>
    )
}