import React from "react";
import {ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";

export function ListItemCredits(props: {
    icon?: React.ReactElement
    primary: string
    href: string
    open: boolean
}) {
    const { icon, primary, href, open } = props;

    return (
        <li>
            <ListItem button>
                <a
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: 'flex', width: '100%', textDecoration: 'none', color: 'inherit' }}
                >
                    {icon ? <ListItemIcon sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                    }}>{icon}</ListItemIcon> : null}
                    <ListItemText
                        primary={
                            <Typography sx={{fontSize: "0.8rem"}} variant={"subtitle1"}>{primary}</Typography>
                        }
                        sx={{ opacity: open ? 1 : 0 }} />
                </a>
            </ListItem>
        </li>
    );
}