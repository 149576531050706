import React from "react";
import {Link as RouterLink, LinkProps as RouterLinkProps} from "react-router-dom";
import {Link, ListItemButton, ListItemIcon, ListItemText, Tooltip} from "@mui/material";

export const listItemIconStyles = (open: boolean) => {
    return {
        minWidth: 0,
        mr: open ? 3 : 'auto',
        justifyContent: 'center',
    }
}
export const listItemTextStyles = (open: boolean) => {
    return { opacity: open ? 1 : 0 }
}

export function ListItemLink(props: {
    icon?: React.ReactElement
    primary: string
    to: string
    disabled: boolean
    open: boolean
}) {
    const { icon, primary, to, disabled, open } = props;

    const renderLink = React.useMemo(
        () =>
            (to.startsWith("http"))
                // handle outbound redirects
                ? React.forwardRef((itemProps, ref) => (
                    <Link {...itemProps} href={to} target="_blank"/>
                ))
                // handle routes
                : React.forwardRef<any, Omit<RouterLinkProps, 'to'>>((itemProps, ref) => (
                    <RouterLink to={to} ref={ref} {...itemProps} />
                )),
        [to],
    );

    const listItem = <ListItemButton component={renderLink} disabled={disabled}>
        {icon ? <ListItemIcon sx={listItemIconStyles(open)}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} sx={listItemTextStyles(open)} />
    </ListItemButton>

    return (
        <li>
            {open
                ? listItem
                : <Tooltip
                    placement="right"
                    title={primary}
                    arrow
                >{listItem}</Tooltip>
            }
        </li>
    );
}