import React, {useMemo} from "react";
import {GlobalStates} from "../../config/global";
import {Box, Divider, Grid, Link} from "@mui/material";
import {GistAccordions} from "./GistCategoryAccordions";
import {Gist} from "../../generator/responses";
import {OpenInGitHubIconButton} from "./OpenInGitHubIconButton";

const mvnArtifactUrl = "https://mvnrepository.com/artifact"

const boxStyles = {
    typography: 'body1',
    '& > :not(style) ~ :not(style)': {
        ml: 2,
    },
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    color: 'text.secondary',
    '& svg': {
        m: 1.5,
    },
    '& hr': {
        mx: 0.5,
    },
}

export function MavenImportsSection(props: {
    gist: GlobalStates["gistState"][0],
    notificationState: GlobalStates["notificationState"],
}) {
    const {gist, notificationState} = props

    const language = gist?.filenames[0].endsWith(".kt") ? "Kotlin" : "Java"

    const codegenJavaImports = [
        <Link target="_blank" href={`${mvnArtifactUrl}/link.codegen/salesforce-utils-java/1.0`}>
            Salesforce Codegen Utils
        </Link>,
    ]
    const codegenKotlinImports = [
        <Link target="_blank" href={`${mvnArtifactUrl}/link.codegen/salesforce-utils-kotlin/1.0`}>
            Salesforce Codegen Utils
        </Link>,
    ]

    const imports = <Box
        sx={boxStyles}
    >
        {(language === "Java") ? codegenJavaImports : codegenKotlinImports}
    </Box>

    const javaGist: Gist = {
        id: "3797ca58986c5bae2273c3ca78024277",
        htmlUrl: "https://gist.github.com/Vergil333/3797ca58986c5bae2273c3ca78024277",
        filenames: ["build.gradle", "build.gradle.kts", "pom.xml"],
    }
    const kotlinGist: Gist = {
        id: "5b897ba3d1e7f0e60f2d5c8084ac0c12",
        htmlUrl: "https://gist.github.com/Vergil333/5b897ba3d1e7f0e60f2d5c8084ac0c12",
        filenames: ["build.gradle", "build.gradle.kts", "pom.xml"],
    }
    const languageSpecificGist = (language === "Kotlin") ? kotlinGist : javaGist

    return useMemo(() => {
        if (gist?.filenames?.length) {
            return <>
                <Divider textAlign="left"><OpenInGitHubIconButton gist={languageSpecificGist}/>Dependency imports</Divider>
                <Grid item xs={12} paddingBottom={"50px"}>
                    <GistAccordions gist={languageSpecificGist} notificationState={notificationState}></GistAccordions>
                    {imports}
                </Grid>
            </>
        }
        return null
    }, [gist])
}