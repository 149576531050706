import {Notification} from "../types";

const getContent = (table: HTMLTableElement): string => {
    const rows = table.querySelectorAll('tr');
    let text = '';

    rows.forEach((row) => {
        const lineContent = row.querySelector('.blob-code-inner');
        if (lineContent) {
            text += lineContent.textContent + '\n';
        }
    });

    // Remove consecutive newlines
    text = text.replace(/\n{3,}/g, '\n\n');

    return text
};

const getFileName = (table: HTMLTableElement): string => {
    return table.getAttribute('data-tagsearch-path') || '';
};

const createFileFromTable = (table: HTMLTableElement): File => {
    const fileName = getFileName(table);
    const content = getContent(table);
    const type = "text/plain";//"application/octet-stream"

    const blob = new Blob([content], {type});
    return new File([blob], fileName, {type});
};

export async function copyFile(iframeId: string, setNotification: (newVal: (Notification | undefined)) => void) {
    const iframe = document.getElementById(iframeId) as HTMLIFrameElement;
    if (!iframe || !iframe.contentDocument) {
        setNotification({severity: "error", message: 'Failed to access iframe content.'})
        return;
    }

    const table = iframe.contentDocument.querySelector('table') as HTMLTableElement;
    if (!table) {
        setNotification({severity: "error", message: 'Failed to find the table element.'})
        return;
    }

    // Firefox workaround (it doesn't support ClipboardItem yet (so far only in Nightly build))
    if (navigator.userAgent.indexOf("Firefox") != -1) {
        try {
            await navigator.clipboard.writeText(getContent(table))
            setNotification({severity: "info", message: 'Copied content to clipboard'})
        } catch (error) {
            setNotification({severity: "error", message: `Failed to copy: ${error}`})
        }
        return
    }

    const file: File = createFileFromTable(table)
    const clipboardItem = new ClipboardItem({[file.type]: file})

    await navigator.clipboard.write([clipboardItem])
    try {
        await navigator.clipboard.write([clipboardItem])
        setNotification({severity: "info", message: 'Copied content to clipboard'})
    } catch (error) {
        setNotification({severity: "error", message: `Failed to copy: ${error}`})
    }
}

export async function copyAllFiles(setNotification: (newVal: (Notification | undefined)) => void) {
    const iframes = document.querySelectorAll('iframe');
    const tables = Array.from(iframes)
        .map(iframe => iframe.contentDocument?.querySelector('table'))
        .filter(value => value != null) as HTMLTableElement[]

    const files: File[] = tables.map(table => createFileFromTable(table))

    const clipboardItems = files.map(file => new ClipboardItem({[file.type]: file}))

    // Copy files to the clipboard
    try {
        await navigator.clipboard.write(clipboardItems);
        setNotification({severity: 'info', message: 'All files copied to clipboard'});
    } catch (err) {
        setNotification({severity: 'error', message: `Failed to copy: ${err}`});
    }
}

/**
 * Not all browsers support File type in clipboard. This function is for testing it.
 * @param setNotification
 */
export async function copyTest(setNotification: (newVal: (Notification | undefined)) => void) {
    // const type = "text/plain";
    const type = "application/octet-stream";

    const blob1 = new Blob(["public interface SObjectInterface {}"], {type});
    const file1 = new File([blob1], "SObjectInterface.java", {type});

    const blob2 = new Blob(["public enum SObjectType {\n" +
    "    @JsonProperty(\"Account\") ACCOUNT(\"Account\"),\n" +
    "    @JsonProperty(\"TestObject__c\") TEST_OBJECT__C(\"TestObject__c\"),\n" +
    "    ;\n" +
    "\n" +
    "    SObjectType(String jsonName) {}\n" +
    "}"], {type});
    const file2 = new File([blob2], "SObjectType.java", {type});

    const data = [
        new ClipboardItem({[file1.type]: file1}),
        // new ClipboardItem({[file2.type]: file2}),// when there's more, then 1 item, it fails
    ];

    try {
        await navigator.clipboard.write(data)
        setNotification({severity: "info", message: 'Copied content to clipboard'})
    } catch (error) {
        setNotification({severity: "error", message: `Failed to copy: ${error}`})
    }
}