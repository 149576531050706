import axios, {AxiosInstance, AxiosPromise} from "axios";
import {
    QueryGetObjectsResponse,
    RecordObject,
    RecordObjectField,
    SalesforceLoginResponse,
    SalesforceOAuthCredentialsOAuth,
    SalesforceObjectType,
} from "./types";
import {ServerEnv, SfAppCredentialsEnv} from "../config/envVariables";
import {RouterLinks} from "../config/RouterLinks";
import {GlobalStates} from "../config/global";
import {pushErrorToNotification} from "../generator/backendClient";

// CONNECTED APP
/**
 * if responseCode = code -> get code which is used to in loginToSalesforceWithCode(code)
 * @see [Web Server flow](https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_web_server_flow.htm)
 *
 * if responseCode = token -> get all auth credentials after # append to callback url which has to be immediately
 * removed from url and history.
 * @see [User-Agent flow](https://help.salesforce.com/s/articleView?id=sf.remoteaccess_oauth_user_agent_flow.htm)
 *
 * @param responseCode is GRANT_TYPE value
 */
export const connectedAppOAuthUrl = (responseCode: string) => "https://login.salesforce.com/services/oauth2/authorize" +
    `?client_id=${SfAppCredentialsEnv.client_id}` +
    `&redirect_uri=${ServerEnv.frontendUrl}/${RouterLinks.salesforce}/${RouterLinks.status}` +
    `&response_type=${responseCode}`

// LOGINS
/**
 * @deprecated
 */
export const loginToSalesforceWithCode = (credentials: SalesforceOAuthCredentialsOAuth): AxiosPromise<SalesforceLoginResponse> => {
  return axios({
      method: "POST",
      url: "https://login.salesforce.com/services/oauth2/token",
      params: credentials,
      headers: {
          Accept: "application/json",
          "Content-type": "application/x-www-form-urlencoded",
      },
      data: (Object.keys(credentials) as Array<keyof SalesforceOAuthCredentialsOAuth>)
          .map((attr: keyof SalesforceOAuthCredentialsOAuth) => `${attr}=${credentials[attr]}`)
          .join("&"),
  })
}

// QUERIES
const SF_API_VERSION = "v53.0"
const QUERY_URL = `/services/data/${SF_API_VERSION}/query`
const FIELD_DEFINITION = "FieldDefinition"
const ENTITY_DEFINITION = "EntityDefinition"
// https://developer.salesforce.com/docs/atlas.en-us.234.0.salesforce_app_limits_cheatsheet.meta/salesforce_app_limits_cheatsheet/salesforce_app_limits_platform_api.htm
const CONCURRENT_LIMIT_DEV = 5// dev, trials
const CONCURRENT_LIMIT_PROD = 25// prod, sandboxes
const OBJECTS_IN_QUERY_LIMIT = 50// max URI length is 2000 bytes
const RECORDS_LIMIT = 1000

const COUNT = (from: string, where: string) => `SELECT COUNT() FROM ${from} ${where}`

const SELECT_ALL_OBJECTS = (where: string) =>
    `SELECT QualifiedApiName FROM ${ENTITY_DEFINITION} ${where}ORDER BY QualifiedApiName`

const SELECT_FIELDS = (objects: string[]) =>
    `SELECT ${ENTITY_DEFINITION}.QualifiedApiName,QualifiedApiName,DataType,NamespacePrefix,MasterLabel,Label,IsNillable,Description` +
    ` FROM ${FIELD_DEFINITION} WHERE ${ENTITY_DEFINITION}.QualifiedApiName IN ('${objects.join("','")}')`

const ITERATION = (offset: number, limit: number = 1000) => ` LIMIT ${limit} OFFSET ${offset}`

// QUERY CALLS
/**
 * @deprecated
 */
export const createAxiosQueryInstance = (request: SalesforceLoginResponse) => axios.create({
    baseURL: request.instanceUrl,
    method: "GET",
    headers: {
        Accept: "application/json",
        Authorization: `Bearer ${request.accessToken}`,
    },
})

const countRecords = async (
    from: string,
    where: string,
    axiosInstance: AxiosInstance,
): Promise<number> => axiosInstance.get(QUERY_URL, {
        params: {q: COUNT(from, where)},
    })
        .then(value => (value.data as QueryGetObjectsResponse<any>).totalSize)

/**
 * @deprecated
 */
export const queryAllObjects = async (
    type: SalesforceObjectType,
    notificationState: GlobalStates["notificationState"],
    axiosInstance: AxiosInstance,
): Promise<string[]> => {
    let where = ""
    switch (type) {
        case "all": where = ""; break
        case "custom": where = "WHERE KeyPrefix LIKE 'a%' "; break
        case "standard": where = "WHERE NOT KeyPrefix LIKE 'a%' "; break
    }

    return axiosInstance.get(QUERY_URL, {
        params: {q: SELECT_ALL_OBJECTS(where)},
    })
        .then(value => (value.data as QueryGetObjectsResponse<RecordObject>).records.map(value => value.QualifiedApiName))
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return []
        })
}

// FIELDS
const whereFields = (objects: string[]) => `WHERE ${ENTITY_DEFINITION}.QualifiedApiName IN ('${objects.join("','")}')`

/**
 * @deprecated
 */
export const queryFieldsCount = async (
    objects: string[],
    notificationState: GlobalStates["notificationState"],
    axiosInstance: AxiosInstance,
) => await countRecords(FIELD_DEFINITION, whereFields(objects), axiosInstance)
    .catch(reason => {
        pushErrorToNotification(reason, notificationState)
        return 0
    })

/**
 * @deprecated
 */
export const queryFieldsInBatches = async (
    objects: string[],
    notificationState: GlobalStates["notificationState"],
    axiosInstance: AxiosInstance,
    debug: boolean = false,
): Promise<RecordObjectField[]> => {
    let result: RecordObjectField[] = []

    while (objects.length > 0) {
        const batch = objects.splice(0, OBJECTS_IN_QUERY_LIMIT)
        const response = queryFields(batch, notificationState, axiosInstance, debug)

        result.push(...(await response))
    }

    return result
}

/**
 * @deprecated
 */
const queryFields = async (
    objects: string[],
    notificationState: GlobalStates["notificationState"],
    axiosInstance: AxiosInstance,
    debug: boolean = false,
): Promise<RecordObjectField[]> => {
    const where = whereFields(objects)

    if (!axiosInstance) {
        notificationState[1]({severity: "error", message: "Axios instance is undefined. Try to re-login."})
        return []
    }
    let uriLength = QUERY_URL + "?q=" + SELECT_ALL_OBJECTS(where)
    let uriLengthInBytes = new Blob([uriLength]).size
    if (uriLengthInBytes > 1900) {
        notificationState[1]({severity: "error", message: "Query in URI is too long. Try it with fewer objects."})
        console.error(uriLength)
        return []
    }

    let totalSize = await countRecords(FIELD_DEFINITION, where, axiosInstance)
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return 0
        })
    if (debug) console.log("totalSize" + totalSize)

    const iterations = Array.from(Array(Math.trunc(totalSize / RECORDS_LIMIT)+1).keys())
        .map(value => ITERATION(value*RECORDS_LIMIT, RECORDS_LIMIT))

    let result: RecordObjectField[] = []

    while (iterations.length) {
        const batch = iterations.splice(0, CONCURRENT_LIMIT_DEV)
        if (debug) console.log(batch)
        const responses = Promise.all(batch.map(value =>
            axiosInstance.get(QUERY_URL, {
                params: {q: SELECT_FIELDS(objects) + value},
            })
        ))
            .then(value => value.flatMap(value => (value.data as QueryGetObjectsResponse<RecordObjectField>).records))
            .catch(reason => {
                pushErrorToNotification(reason, notificationState)
                return [] as RecordObjectField[]
            })
        if (debug) console.table(await responses)

        result.push(...(await responses))
    }

    if (debug) console.table(result)
    return result
}