import {ServerEnv} from "../config/envVariables";
import axios from "axios";
import {GeneratedGistResponse} from "./responses";
import {GeneratorRequest} from "./requests";
import {GlobalStates} from "../config/global";
import {validateAuthToken} from "../auth/refreshService";

export function pushErrorToNotification(reason: any, notificationState: GlobalStates["notificationState"]) {
    let errorMessage = reason?.response?.data?.message || reason?.toJSON()?.message

    notificationState[1]({
        severity: "error",
        message: JSON.stringify(errorMessage),
    })
    return undefined
}

// CODE GENERATION

export const generateCodeDemo = async (
    requestBody: GeneratorRequest,
    notificationState: GlobalStates["notificationState"],
): Promise<GeneratedGistResponse | null> => {

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/generate/gist/objects/demo",
        method: "POST",
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        data: requestBody,
    })
        .then(value => {
            let result = value.data as GeneratedGistResponse
            return result
        })
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return null
        })
}

export const generateCode = async (
    requestBody: GeneratorRequest,
    signInState: GlobalStates["signInState"],
    notificationState: GlobalStates["notificationState"],
): Promise<GeneratedGistResponse | null> => {
    const checkedSignIn = await validateAuthToken({
        notificationState: notificationState,
        signInState: signInState,
    })

    return axios({
        baseURL: ServerEnv.backendUrl,
        url: "/generate/gist/objects",
        method: "POST",
        headers: {
            Accept: 'application/json',
            Authorization: `jws ${checkedSignIn?.accessToken}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
        data: requestBody,
    })
        .then(value => {
            let result = value.data as GeneratedGistResponse
            return result
        })
        .catch(reason => {
            pushErrorToNotification(reason, notificationState)
            return null
        })
}