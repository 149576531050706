import {FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import {SalesforceInstances} from "../types";
import React, {useEffect, useMemo, useState} from "react";
import {GlobalStates} from "../../config/global";
import {DEMO_INSTANCE} from "./Generator";
import {getSfInstances} from "../backendClient";

export function SalesforceRadioGroup(props: {
    signInState: GlobalStates["signInState"],
    salesforceAuthState: GlobalStates["salesforceAuthState"],
    selectedSfAccountState: [(string), (newVal: (string)) => void],
    notificationState: GlobalStates["notificationState"],
}) {
    const {signInState, salesforceAuthState, selectedSfAccountState, notificationState} = props
    const [signIn] = signInState
    const [salesforceAuth] = salesforceAuthState
    const [selectedSfAccount, setSelectedSfAccount] = selectedSfAccountState

    const [allSfInstances, setAllSfInstances] = useState<SalesforceInstances[]>([])
    const sfInstanceRadios = useMemo(() => allSfInstances
        .map(sfInstance => {
                return (sfInstance.instanceUrl === selectedSfAccount)
                    ? <FormControlLabel
                        control={<Radio/>}
                        value={sfInstance.instanceUrl}
                        label={sfInstance.instanceUrl}
                    />
                    : <Tooltip
                        placement="right-start"
                        title="to change instance, go to 'Salesforce login' page"
                    ><FormControlLabel
                        control={<Radio/>}
                        value={sfInstance.instanceUrl}
                        label={sfInstance.instanceUrl}
                        disabled={true}
                    /></Tooltip>
            }
        ), [allSfInstances])

    const selectSfInstance = () => {
        if (signIn != null && salesforceAuth != null) {
            return (salesforceAuth?.instanceUrl == null)
                ? notificationState[1]({severity: "error", message: "Error selecting salesforce instance"})// should not happen, but better safe than sorry
                : setSelectedSfAccount(salesforceAuth?.instanceUrl)
        }
        return setSelectedSfAccount(DEMO_INSTANCE)
    }

    const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setSelectedSfAccount(event.target.value)
        console.log(event.target.value)
        if (event.target.value === DEMO_INSTANCE) return setSelectedSfAccount(DEMO_INSTANCE)
        return setSelectedSfAccount(salesforceAuth?.instanceUrl || "error")
    }

    useEffect(() => {
        if (signIn == null) return
        getSfInstances(signInState, notificationState).then(value => setAllSfInstances(value))
        selectSfInstance()
    }, [signIn])

    // Check SF instance if there are some
    useEffect(() => {
        selectSfInstance()
    }, [salesforceAuth])

    return (
        <FormControl>
            <FormLabel component="legend">Which account you want to use?</FormLabel>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedSfAccount}
                onChange={onRadioChange}
            >
                <FormControlLabel value={DEMO_INSTANCE} control={<Radio />} label="Demo" />
                {sfInstanceRadios}
            </RadioGroup>
            {(selectedSfAccount === DEMO_INSTANCE) &&
                <FormHelperText>You are using demo data. Feel free to play with them, it's free.</FormHelperText>
            }
        </FormControl>
    )
}