import {GlobalStates} from "../config/global";
import {Temporal} from "@js-temporal/polyfill";
import {refreshAccessToken} from "./backendClient";
import {Buffer} from "buffer";
import {pushErrorToNotification} from "../generator/backendClient";
import {CookieService} from "../cookies/cookiesService";

async function refreshToken(props: {
    notificationState: GlobalStates["notificationState"],
    setSignIn: GlobalStates["signInState"][1],
}) {
    const {notificationState} = props

    const authResponse = async () => {
        const sign = await refreshAccessToken(notificationState)
        props.setSignIn(sign)
        return sign
    }
    authResponse().catch(reason => pushErrorToNotification(reason, notificationState))

    return authResponse()
}

function decodeTokenComponent(token: string) {
    const buff: Buffer = Buffer.from(token, "base64")
    const text = buff.toString('ascii')
    return JSON.parse(text)
}

/**
 *
 * @param jwt access token
 * @return epoch in seconds
 */
function extractEpoch(jwt: string): number {
    const [, payloadEncoded] = jwt.split('.')
    const payload = decodeTokenComponent(payloadEncoded)

    return payload["exp"]
}

export async function validateAuthToken(props: {
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
}) {
    // console.log("auth token validation start")
    const {notificationState} = props
    const [, setNotification] = notificationState

    const [signIn, setSignIn] = props.signInState
    const accessToken = signIn?.accessToken

    const authExpiresAt = (accessToken != null) ? extractEpoch(accessToken) : null

    const refreshExpiresAtCookie = CookieService.getRefreshExpiresAt()
    const refreshExpiresAt = (refreshExpiresAtCookie != null) ? Number(refreshExpiresAtCookie) : null

    const nowInstant = Temporal.Now.instant().epochSeconds

    if (authExpiresAt != null && (nowInstant < authExpiresAt)) {
        console.log("Access Token is valid")
        return signIn
    }

    if (refreshExpiresAt != null && (nowInstant < refreshExpiresAt)) {
        console.log("Refresh Token is valid")
        try {
            return refreshToken({
                notificationState,
                setSignIn,
            })
        } catch (e: any) {
            const caughtError = e.body || e.toString()
            setNotification({severity: "error", message: `${caughtError}\nPlease go to SignIn page.`})
            CookieService.removeRefreshCookies()
        }
    }

    console.log("Neither Access and Refresh tokens are not valid.")
    return signIn
}