import {Dialog, DialogContent, Zoom} from "@mui/material";

const LANDING_VIDEO_ID = "y_xbtu-7oto"

export function YoutubeModal(props: {
    openState: [(boolean), (newVal: (boolean)) => void],
}) {
    const {openState} = props
    const [isOpen, setIsOpen] = openState

    return (
        <Dialog
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={{maxWidth: "unset"}}
            PaperProps={{sx: {maxWidth: "unset"}}}
            TransitionComponent={Zoom}
        >
            <DialogContent>
                <iframe
                    width="640"
                    height="360"
                    src={`https://www.youtube.com/embed/${LANDING_VIDEO_ID}?autoplay=1`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture, web-share"
                    allowFullScreen
                    title="Embedded youtube"
                />
            </DialogContent>
        </Dialog>
    )
}