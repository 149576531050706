import {GlobalStates} from "../config/global";
import {CodeClientConfig, CodeResponse, useGoogleLogin} from "@react-oauth/google";
import {Button} from "@mui/material";
import {sendGoogleSignInCodeToBackend} from "../auth/backendClient";
import {GAnalyticsEvents} from "../events-trackers/gAnalytics-events";

interface AuthCodeResponses extends Omit<CodeClientConfig, 'client_id' | 'scope' | 'callback'> {
    success: Omit<CodeResponse, 'error' | 'error_description' | 'error_uri'>
    error: Pick<CodeResponse, 'error' | 'error_description' | 'error_uri'>
}

export function GoogleSignInButton(props: {
    notificationState: GlobalStates["notificationState"],
    signInState: GlobalStates["signInState"],
}) {
    const [, setSignIn] = props.signInState
    const {notificationState} = props

    const onSuccessCode = async (response: AuthCodeResponses["success"]) => {
        const authResponse = await sendGoogleSignInCodeToBackend(response.code, notificationState)
        if (authResponse == null) {
            notificationState[1]({
                severity: "error",
                message: "Missing Auth response, please report this to support@codegen.link",
            })
            return
        }

        setSignIn(authResponse)
        GAnalyticsEvents.eventGmailLoginSuccessful()
    }

    const onError = (error: AuthCodeResponses["error"]) => {
        notificationState[1]({
            severity: "error",
            message: JSON.stringify(error.error_description || error.error || "missing error message"),
        })
        GAnalyticsEvents.eventGmailLoginFailed()
    }

    const onGoogleSignIn = useGoogleLogin({
        onSuccess: codeResponse => onSuccessCode(codeResponse),
        onError: errorResponse => onError(errorResponse),
        flow: 'auth-code',
        redirect_uri: "postmessage",
    })

    return (
        <Button variant="contained" color="primary" onClick={() => onGoogleSignIn()}>Login with Google</Button>
    )
}